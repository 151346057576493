<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      @keydown="handleKeydownClick"
      ref="dialog"
      :max-width="isOtherSettingPopup ? '650px' : '464px'"
    >
    <v-card>
      <v-card-title v-if="isSaveTemplateButton">
        <span v-if="isCloneTemplate" class="">{{ $t('popup.title_save_new_template')}}</span>
        <span v-else>{{ $t('popup.title_') }}</span>
      </v-card-title>
      <v-card-title v-else>
        <img class="" src="@/assets/images/dialog/question.svg" />
        <span class="" tabindex="0">{{ titlePopupConfirm || $t('register_reduction.title_popup_confirm') }}</span>
      </v-card-title>
      <v-card-text>
        <span v-html="message"></span>
        <slot></slot>
      </v-card-text>
      <v-card-actions :class="isOtherSettingPopup && 'other-popup'">
        <common-button v-if="isSaveTemplateButton" class="left-button-popup" @action="close" :label="$t('register_data.button_close')"/>
        <common-button v-else class="left-button-popup" :class="borderFocusNone && 'border-focus-none'" @action="close" :label="closeText"/>
        <common-button v-if="logout" class="right-button-popup btn-red-mid" @action="doLogout" :label="buttonLogout"/>
        <common-button v-if="confirm" class="right-button-popup" :class="{'btn-red-mid': !isSaveTemplateButton && !isConfirmSettingTaget, 'border-focus-none': borderFocusNone}" @action="submit" :label="confirmText"/>
        <common-button v-if="!logout && !confirm" class="right-button-popup" :class="isSaveButtonDelete && 'btn-red-mid'" @action="submit" :label="confirmText || $t('list_menu.label_decision')" />
      </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {KEYS_CODE} from '@/constants/keyboard'
import CommonButton from '@/components/commonApp/Button.vue';
import i18n from '@/lang/i18n';

export default {
  components: {CommonButton},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    isSaveTemplateButton: {
      type: Boolean,
      default: false,
    },
    isConfirmSettingTaget: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: false,
    },
    logout: {
      type: Boolean,
      required: false,
    },
    confirm: {
      type: Boolean,
      required: false,
    },
    isCloneTemplate: {
      type: Boolean,
      default: false,
    },
    isSaveButtonDelete: {
      type: Boolean,
      default: false,
    },
    closeText: {
      type: String,
      default: i18n.t('popup.button_cancel'),
    },
    borderFocusNone: {
      type: Boolean,
      required: false,
    },
    titlePopupConfirm: {
      type: String,
      default: null,
    },
    isOtherSettingPopup: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    };
  },
  computed: {
    buttonLogout() {
      return this.$t('popup.button_logout')
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    doLogout() {
      this.$emit('logout');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    handleKeydownClick(event) {
      if(event.keyCode === KEYS_CODE.ESCAPE) {
        this.close()
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>
