import Vue from "vue";
import VueRouter from "vue-router";
import AuthLayout from "@/layouts/Auth.vue";
import OtherLayout from "@/layouts/Other.vue";
import DefaultLayout from "@/layouts/Default.vue";
import TypeSelectionLayout from '@/layouts/TypeSelection.vue';
import SupplierLayout from '@/layouts/Supplier.vue';
import DBCustomize from "@/views/emissions/db-customize.vue";
import webStorage from "@/utils/webStorage";
import { ROUTES } from "./constants";
import { getScopesAndCategories } from "@/api/emissionCalculations";
import { getListDataPattern } from "@/api/newRegisterData";
import { updateDataBeforeRedirect } from "@/utils/updateDataBeforeRedirect";
import { logoutApi, getUserInfo } from '@/api/auth';
import { getDbCustom, getCasbeeDetail } from '@/api/dbCustomization';
import store from '@/store';
import ProductsDefaultLayout from "@/layouts/products/Default.vue";
import ProductsEmissionsLayout from "@/layouts/products-emissions/Default.vue";
import { ROLE } from '@/constants/role';
import i18n from '@/lang/i18n';
import { handleDecodeToken } from '@/utils/tokenHandle'
Vue.use(VueRouter);

const routes = [
  {
    path     : ROUTES.AUTH,
    component: AuthLayout,
    children : [
      {
        path     : ROUTES.LOGIN,
        name     : "Login",
        component: () => import("../views/auth/Login.vue"),
        meta     : {
          noRequiresAuth: true
        }
      },
      {
        path     : ROUTES.RESET_PASSWORD,
        name     : "Resetpassword",
        component: () => import("../views/auth/ResetPassword.vue"),
        meta     : {
          noRequiresAuth: true
        }
      },
      {
        path     : ROUTES.FORGOT_PASSWORD,
        name     : "ForgotPassord",
        component: () => import("../views/auth/ForgotPassword.vue"),
        meta     : {
          noRequiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.DASHBOARD,
    component: DefaultLayout,
    children : [
      {
        path     : "/",
        name     : "Dashboard",
        component: () => import("@/views/dashboard/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCT_REDUCTION,
        name     : "Reduction",
        component: () => import("@/views/products/reduction/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCT_REDUCTION_SETTING,
        name     : "ReductionSetting",
        component: () => import("@/views/products/reduction/setting-reduction.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCT_REDUCTION_SETTING,
        name     : "ReductionSetting",
        component: () => import("@/views/products/reduction/setting-reduction.vue"),
        meta     : {
          requiresAuth: true
        }
      },
    ]
  },
  {
    path     : "/managements",
    name     : "Managements",
    component: DefaultLayout,
    children : [
      {
        path     : ROUTES.USER_MANAGEMENT,
        name     : "User",
        component: () => import("@/views/managements/users.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.VIEWER_MANAGEMENT,
        name     : "Viewer",
        component: () => import("@/views/managements/viewer.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.GROUPS_MANAGEMENT,
        name: 'UserGroup',
        component: () => import('@/views/managements/groups.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.CREATE_FORM_LINK_PRIMARY_DATA,
        name: 'CreateForm',
        component: () => import('@/views/linkToPrimaryData/create-form.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.EDIT_FORM_LINK_PRIMARY_DATA,
        name: 'EditForm',
        component: () => import('@/views/linkToPrimaryData/edit-form.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.SETTING_AUTO_APPROVAL,
        name     : "SettingAutoApproval",
        component: () => import("@/views/managements/auto-approval.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.CSV_FORMAT,
        name     : "csvFormat",
        component: () => import("@/views/managements/csv-format.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.SETTING,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "adminSetting",
        component: () => import("@/views/settingDashboard/Setting.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.EXPORT_REPORT,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "exportReport",
        component: () => import("@/views/export-report/index.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.GROUPS_MANAGEMENT + ROUTES.REGISTER_USER_GROUP,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "registerUserGroup",
        component: () => import("@/views/registerUserGroup/index.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.GROUPS_MANAGEMENT + ROUTES.EDIT_USER_GROUP,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "editUserGroup",
        component: () => import("@/views/registerUserGroup/index.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.EMISSIONS,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "EmissionsIndex",
        component: () => import("@/views/emissions/NewRegister/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.LIST_EMISSIONS,
        name     : "Emissions",
        component: () => import( "@/views/emissions/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.REGISTER_DATA,
        name     : "NewRegisterData",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/NewRegister/RegisterData"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.LIST_EMISSION_TEMPLATES,
        name     : "EmissionsListTemplates",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/NewRegister/ListTemplates"),
        meta     : {
          requiresAuth: true
        }
      }, {
        path     : ROUTES.LIST_EMISSION_SUBMITTED,
        name     : "EmissionsListSubmitted",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/NewRegister/ListSubmitted"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.LIST_EMISSION_TEMPORARY,
        name     : "EmissionsListTemporary",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/NewRegister/ListTemporaries"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.LIST_MENU_EMISSION,
        name     : 'EmissionsListMenu',
        component: () => import(/* webpackChunkName: "register data" */ '@/views/emissions/NewRegister/ListMenu'),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.APPROVAL,
        name     : "Approval",
        component: () => import(/* webpackChunkName: "approval data" */ "@/views/approval"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRIMARY_DATA_MERGE,
        name     : "primaryDataMerge",
        component: () => import(/* webpackChunkName: "approval data" */ "@/views/primary-data-linked"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.FORM_PRIMARY_DATA_MERGE,
        name     : "formPrimaryDataMerge",
        component: () => import(/* webpackChunkName: "approval data" */ "@/views/primary-data-linked/form-primary-data-linked"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.VIEW_DATA,
        name     : "RegisterData",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/NewRegister/ListEmission.vue"),
        // component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/view-emission.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.SCORE_SUMMARY,
        name     : "ScoreSummary",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/emissions/NewRegister/RegisterData.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.CALCULATION_TERM,
        name     : "Calculation Term",
        component: () => import("@/views/emissions/calculation-term.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.FACILITY_MANAGEMENT,
        name     : "Facility",
        component: () => import("@/views/managements/facility.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.REDUCTION_TARGET_LIST_MANAGEMENT,
        name     : "Reduction Target",
        component: () => import("@/views/managements/reduction-target.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.CREATE_REDUCTION_TARGET,
        name     : "Create Reduction Target",
        component: () => import("@/views/products/reduction/setting-reduction.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.CREATE_DATA_CUSTOMIZE,
        name     : "CreateDataCustomize",
        component: DBCustomize,
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.EMISSIONS_BY_BASE_PERIOD,
        name     : "EmissionsByBasePeriod",
        component: () =>
          import(/* webpackChunkName: "create data customize" */ "@/views/emissions/emission-by-base-period.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.EMISSIONS_BY_BASE_CATEGORY,
        name     : "EmissionsByBaseCategory",
        component: () =>
          import(/* webpackChunkName: "create data customize" */ "@/views/emissions/emission-by-base-category.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.CASBEE,
        name     : "CreateCasbee",
        component: () => import("@/views/emissions/dbCustomize/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.CASBEE_DETAIL,
        name     : "CasbeeDetail",
        component: () => import("@/views/emissions/dbCustomize/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.MONITORING,
        name     : "minitoringCasbee",
        component: () => import("@/views/emissions/dbCustomize/monitoring/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : "test",
        name     : "Export report",
        component: () => import("@/views/export-report/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.SETTING_LANGUAGE,
        name     : "Setting language",
        component: () => import("@/views/languages/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.LIST_IDEA_DB_SOURCE,
        name     : "IdeaSourceManagement",
        component: () => import("@/views/managements/idea-source.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.REGISTER_DB_SOURCE,
        name     : "RegisterIdeaSource",
        component: () => import("@/views/idea-source/RegisterIdeaSource.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.SETTING_TARGET,
        name     : "SettingTarget",
        component: () => import("@/views/products/reduction/setting-target.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.SETTING_REDUCTION_INTEGRATE,
        name     : "SettingReductionIntegrate",
        component: () => import("@/views/products/reduction/setting-redution-integrate.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.GROUP_ENTERPRISE_MANAGEMENT,
        name: "enterpriseGroup",
        component: () => import("@/views/managements/group-enterprise.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.LINKED_SERVICE_MANAGEMENT,
        name: "ServiceManagementRegister",
        component: () => import("@/views/linked-service-management/index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.LINKED_SERVICE_MANAGEMENT_REGISTER,
        name: "ServiceManagementRegister",
        component: () => import("@/views/linked-service-management/register.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.CHANGE_PASSWORD_USER,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "Change password",
        component: () => import("@/views/auth/ChangePasswordAdmin.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.OTHERS,
    component: OtherLayout,
    children : [
      {
        path     : ROUTES.PRIVACY,
        name     : "Privacy",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/others/privacy.vue")
      },
      {
        path     : ROUTES.TERM_OF_USER,
        name     : "Term Of Use",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/others/term-of-use.vue")
      },
      {
        path     : ROUTES.VERSIONS,
        name     : "Versions",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/others/versions.vue")
      }
    ]
  },
  {
    path     : ROUTES.OTHERS,
    component: DefaultLayout,
    children : [
      {
        path     : ROUTES.OFFSET_TRANSACTION,
        name     : "Offset transaction app",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/others/offset-transaction-app.vue"),
        meta     : {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.CONTACT,
    component: DefaultLayout,
    children : [
      {
        path     : "",
        name     : "Contact",
        component: () => import("@/views/contact/index.vue")
      }
    ]
  },
  {
    path     : ROUTES.PRODUCTS_EMISSION,
    component: ProductsEmissionsLayout,
    children : [
      {
        path: ROUTES.DETAIL_PRODUCT,
        name: "DetailProduct",
        component: () => import("@/views/products/workflow/register-product.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCT_LIST,
        name     : "ProductsEmissionNew",
        component: () => import("@/views/products/workflow/list-product.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCT_SUBMITTED_LIST,
        name     : "ProductsListSubmittedNew",
        component: () => import("@/views/products/workflow/list-submitted.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCT_APPROVED_LIST,
        name     : "ProductsApprovalNew",
        component: () => import("@/views/products/workflow/list-approval.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCT_EMISSIONS_DETAIL,
        name     : "EmissionsDetailProduct",
        component: () => import("@/views/products/workflow/emissions-detail.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.FACILITY_MANAGEMENT,
        name     : "ProductsFacility",
        component: () => import("@/views/products/managements/facility.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.CASBEE,
        name     : "ProductsCreateCasbee",
        component: () => import("@/views/products/emissions/dbCustomize/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.CASBEE_DETAIL,
        name     : "ProductsCasbeeDetail",
        component: () => import("@/views/products/emissions/dbCustomize/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.MONITORING,
        name     : "ProductsminitoringCasbee",
        component: () => import("@/views/emissions/dbCustomize/monitoring/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.SETTING,
        name     : "adminSetting",
        component: () => import("@/views/products/settingDashboard/Setting.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.PRODUCTS_EMISSION + ROUTES.GROUPS_MANAGEMENT,
        name: 'UserGroupProducts',
        component: () => import('@/views/managements/groups.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.USER_MANAGEMENT,
        name     : "User",
        component: () => import("@/views/products/managements/users.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.VIEWER_MANAGEMENT,
        name     : "Viewer",
        component: () => import("@/views/products/managements/viewer.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.SETTING_AUTO_APPROVAL,
        name     : "SettingAutoApprovalProduct",
        component: () => import("@/views/products/managements/auto-approval.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.FACILITY_MANAGEMENT,
        name     : "Viewer",
        component: () => import("@/views/products/managements/facility.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE,
        name     : "Viewer",
        component: () => import("@/views/products/emissions/db-customize.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.CALCULATION_TERM,
        name     : "Viewer",
        component: () => import("@/views/products/emissions/calculation-term.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.CHANGE_PASSWORD_USER,
        name     : "Viewer",
        component: () => import("@/views/products/auth/ChangePasswordAdmin.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.CONTACT,
        name     : "Contact",
        component: () => import("@/views/products/contact/index.vue")
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.OTHERS + '/' + ROUTES.OFFSET_TRANSACTION,
        name     : "Offset transaction app",
        component: () => import(/* webpackChunkName: "register data" */ "@/views/products/others/offset-transaction-app.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.EXPORT_REPORT,
        name     : "exportReport",
        component: () => import("@/views/products/export-report/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.PRODUCTS_EMISSION + ROUTES.GROUPS_MANAGEMENT + ROUTES.REGISTER_USER_GROUP,
        name: "registerUserGroupProducts",
        component: () => import("@/views/registerUserGroup/index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.GROUPS_MANAGEMENT + ROUTES.EDIT_USER_GROUP,
        name     : "editUserGroupProducts",
        component: () => import("@/views/registerUserGroup/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.PRODUCTS_EMISSION + ROUTES.SETTING_LANGUAGE,
        name     : "Setting language",
        component: () => import("@/views/products/languages/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      // {
      //   path: ROUTES.PRODUCTS_EMISSION + ROUTES.LINKED_SERVICE_MANAGEMENT,
      //   name: "ProductLinkedServiceManagement",
      //   component: () => import("@/views/linked-service-management/index.vue"),
      //   meta: {
      //     requiresAuth: true
      //   }
      // },
      {
        path: ROUTES.PRODUCTS_EMISSION + ROUTES.LINKED_SERVICE_MANAGEMENT_REGISTER,
        name: "ProductLinkedServiceManagementRegister",
        component: () => import("@/views/linked-service-management/register.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.REGISTER_PRODUCT,
        name: "RegisterProduct",
        component: () => import("@/views/products/workflow/register-product.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.REGISTER_EMISSIONS,
        name: "RegisterEmissions",
        component: () => import("@/views/products/workflow/register-emissions.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.PRODUCTS_EMISSION + ROUTES.CSV_FORMAT,
        name: "csvFormat",
        component: () => import("@/views/managements/csv-format.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path     : ROUTES.PRODUCTS,
    component: ProductsDefaultLayout,
    children : [
      {
        path     : "",
        name     : "ProductsDashboard",
        component: () => import("@/views/products/dashboard/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.LIST_PRODUCTS,
        name     : "list",
        component: () => import( "@/views/products/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
    ]
  },
  {
    path     : ROUTES.TYPE_SELECTION,
    component: TypeSelectionLayout,
    children : [
      {
        path     : "",
        name     : "Type Selection",
        component: () => import("@/views/type-selection.vue"),
        meta     : {
          requiresAuth: true
        }
      },
    ]
  },

  {
    path     : '',
    component: SupplierLayout,
    children : [
      {
        path     : ROUTES.SUPPLIER,
        name     : "Registration primary data",
        component: () => import("@/views/supplier/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.REGISTER_PRIMARY_DATA,
        name     : "List primary data",
        component: () => import("@/views/supplier/formRegister.vue"),
        meta     : {
          requiresAuth: true
        }
      },
      {
        path: ROUTES.CONTACT_SUPPLIER,
        name: 'ContactSupplier',
        component: () => import('@/views/supplier/contact'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path     : ROUTES.SETTING_LANGUAGE_SUPPLIER,
        name     : "Setting language",
        component: () => import("@/views/languages/index.vue"),
        meta     : {
          requiresAuth: true
        }
      },
    ]
  },
  {
    path     : "/404",
    component: () => import(/* webpackChunkName: "404" */ "@/views/error-page/404.vue")
  },
  {
    path    : "*",
    redirect: "/404"
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x       : 0,
      y       : 0,
      behavior: "smooth"
    };
  }
});

// define list router approval can't access
const listRouterApprovalBlocked = [
  ROUTES.USER_MANAGEMENT,
  ROUTES.VIEWER_MANAGEMENT,
  ROUTES.FACILITY_MANAGEMENT,
  ROUTES.CALCULATION_TERM,
]

export const listRouterSupplierAcceptAccess = [
  ROUTES.SUPPLIER,
  ROUTES.CONTACT_SUPPLIER,
  ROUTES.SETTING_LANGUAGE_SUPPLIER,
  ROUTES.REGISTER_PRIMARY_DATA
]

router.beforeEach(async(to, from, next) => {
  if (to.matched.some(rec => rec.meta.requiresAuth)) {
    let token = webStorage.getToken();
    const chunkTokenObj = localStorage.getItem('chunkAuthObj')
    if(!token && chunkTokenObj) { // check token & chunk token
      const tokenFromChunkData = handleDecodeToken(JSON.parse(chunkTokenObj))
      token = tokenFromChunkData
      webStorage.setToken(tokenFromChunkData, {domain: process.env.VUE_APP_COOKIE_DOMAIN || ".carbonix.ai"});
    }

    if (!token) {
      webStorage.removeAll();
      next({ name: "Login", query: { primary_uuid: to.query?.primary_uuid} });
    } else {
      let userInfo = await getUserInfo();
      store.dispatch('userData/updateLanguage', userInfo?.language)
      localStorage.setItem('language', userInfo?.language?.code)
      if (to.query?.banner === "true") {
        next({name: "Contact", query: to.query})
      }
      if (from.name === null) {
        if(userInfo?.language?.code && i18n.locale !== userInfo?.language?.code) {
          i18n.locale = userInfo?.language?.code;
          location.reload()
        }
      };
      //check permission approval role
      // if (to.matched.some(rec => ["otherSetting", "enterpriseGroup"].includes(rec.name))) {
      if (to.matched.some(rec => rec.name === "enterpriseGroup")) {
        if(userInfo.plan_type.is_pcaf) {
          next();
        }else {
          next({path: ROUTES.SETTING});
        }
      }
      const routesToCheck = ['Reduction', 'Reduction Target', 'Create Reduction Target', 'ReductionSetting'];
      if (to.matched.some(rec => routesToCheck.includes(rec.name))) {
        if (userInfo.plan_type.is_reduction_target === false) {
          next({ path: '/' });
        } else{
          next();
        }
      }
      const roleId = webStorage.getRoleId()
      if (roleId === 4 && to.path === '/approval') {
        next({path: '/'});
      }
      const routesPrimaryDataOnlyAdmin = [ROUTES.PRIMARY_DATA_MERGE, ROUTES.FORM_PRIMARY_DATA_MERGE, ROUTES.CREATE_FORM_LINK_PRIMARY_DATA]
      if ((roleId !== ROLE.ADMIN || !userInfo.plan_type.is_primary_data) && (routesPrimaryDataOnlyAdmin.includes(to.path) || to.path.startsWith('/link-to-primary/edit-form') || to.path.startsWith('/emissions/register/primary-data'))) {
        next({path: '/'});
      }
      if (
        (roleId === ROLE.APPROVAL || roleId === ROLE.USER) &&
        (to.path.startsWith(ROUTES.REDUCTION_TARGET_LIST_MANAGEMENT) || to.path === '/reduction')
      ) {
        next({ path: '/' });
      }
      if(listRouterApprovalBlocked.includes(to.path) && roleId === 5) {
        next({ path: "/404" });
      }
      if(!listRouterSupplierAcceptAccess.includes(to.path) && roleId === 6) {
        next({ path: ROUTES.SUPPLIER, query: { primary_uuid: to.query?.primary_uuid} });
      }
      if (to.name === "ScoreSummary" && !userInfo.plan_type?.is_pcaf) {
        next({ path: "/emissions/view/3/15" })
      }
      if (to.matched.some(rec => rec.name === "RegisterData") && to.params?.scope === "3") {
        let isEnableScope3     = true;
        await getScopesAndCategories({ contractor_id: store.state.userData.currentUser.user.contractor_id }).then((res) => {
          let displayScope3 = res.find((object) => {
            return object.hasOwnProperty("display_scope_3");
          });
          isEnableScope3    = displayScope3.display_scope_3;
          return isEnableScope3;
        });
        if (isEnableScope3) {
          next();
        } else {
          next({ path: "/404" });
        }
      } else if (['approval', 'list-submitted', 'list-menu', 'primary-data'].includes(to.params?.scope)) {
        try {
          const responseData = await getListDataPattern({id: to.params?.category, page: 1, per_page: 1 }); //page, per_page for performance before redirect
          if (responseData?.workflow_data) {
            const workflowData = {
              ...responseData.workflow_data,
              scope_id: parseInt(responseData.workflow_data.scope),
              category_id: parseInt(responseData.workflow_data.category),
              approved_by: responseData.workflow_data.approved_by,
            };
            await updateDataBeforeRedirect(workflowData);
          }
        } catch (error) {
          next({ path: "/approval" });
        }
      } else if (["CreateCasbee", "minitoringCasbee"].includes(to.name)) {
        const dbCustomizeRes = await getDbCustom({
          contractor_id: store.state.userData.currentUser.user.contractor_id,
        });
        if (!dbCustomizeRes.is_casbee) {
          next({name: 'CreateDataCustomize'})
        }
      } else if (to.name === "CasbeeDetail") {
        if (Number.isInteger(parseInt(to.params.id))) {
          await getCasbeeDetail(parseInt(to.params.id))
            .then(() => {
              next();
            })
            .catch(() => {
              next({name: 'CreateDataCustomize'})
            });
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }

  // check redirect to home in auth layout
  if (to.matched.some(rec => rec.meta.noRequiresAuth)) {
    let token = webStorage.getToken();
    if (token) {
      if(to.path === '/auth/login' && to.query?.email && to.query?.id) {
        await logoutApi();
        next({ name: "Login", query: to.query });
      } else {
        if (to.query?.banner === "true") {
          next({name: "Contact", query: to.query})
        } else {
          next({ name: "Type Selection", query: to.query });
        }
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
