import jwt_decode from 'jwt-decode';

import webStorage from '@/utils/webStorage';

import {refreshToken} from '@/api/auth';

const defaultState = () => ({
  authenticated: false,
  adminRole: 0,
  contractor: null,
  currentUser: {},
  timeExp: null,
  isTokenExpired: false,
  timeoutToken: null,
  message: '',
  contactForm: {},
  planType: {},
  isInProductPage: false,
  isSettingPcaf: true,
  isExistPcaf: true,
  language: {},
  existDbSource: false,
  isShowNotiPcaf: true,
  dbSourceId: null
});

const getters = {
  getContractor: (state) => state.contractor,
  getMessage: (state) => state.message,
};

const state = defaultState();

export const mutations = {
  updateAuthentication(state, authenticated) {
    state.authenticated = authenticated;
  },

  updateAdminRole(state, adminRole) {
    state.adminRole = adminRole;
  },

  updateSelectContractor(state, contractor) {
    state.contractor = contractor;
  },
  updateCurrentUser(state, currentUser) {
    state.currentUser = currentUser;
  },
  updateTimeExp(state, tokenTime) {
    state.timeExp = tokenTime;
  },
  updateTokenExpired(state, newInfo) {
    state.isTokenExpired = newInfo;
  },
  resetState(state) {
    Object.assign(state, defaultState());
  },
  updateUserName(state, userName) {
    state.currentUser.user.name = userName;
  },

  updateContractorName(state, contractor) {
    state.currentUser.user.contractor.name = contractor;
  },
  updateMessage(state, message) {
    state.message = message;
  },
  updateContactForm(state, form) {
    state.contactForm = form;
  },
  updateContractorData(state, contractor) {
    state.contractor = contractor.id;
    state.currentUser.user.contractor = contractor;
    state.currentUser.user.contractor_id = contractor.id;
  },
  updatePlanType(state, planType) {
    state.planType = planType;
  },
  updateIsExistDbSource(state, existDbSource) {
    state.existDbSource = existDbSource;
  },
  getIsShowNotificationPcaf(state, isShowNotiPcaf) {
    state.isShowNotiPcaf = isShowNotiPcaf;
  },
  updateIdDbSource(state, dbSourceId) {
    state.dbSourceId = dbSourceId;
  },
  
  updateIsInProductPage(state, isInProductPage) {
    state.isInProductPage = isInProductPage;
  },
  updateMethodSetting(state, newVal) {
    state.isSettingPcaf = newVal;
  },
  updateIsExistPcaf(state, newState) {
    state.isExistPcaf = newState;
  },
  updateLanguage(state, newVal) {
    state.language = newVal
  }
};

export const actions = {
  updateContractorSelect({commit}, contractor) {
    commit('updateSelectContractor', contractor);
  },

  updateInfoUser({commit}, currentUser) {
    commit('updateCurrentUser', currentUser);
  },
  updateToken({commit}, refreshToken) {
    commit('updateTimeExp', refreshToken);
  },

  resetState({commit}) {
    commit('resetState');
  },

  updateUserName({commit}, userName) {
    commit('updateUserName', userName);
  },

  actionUpdateAuthentication({commit}, status) {
    commit('updateAuthentication', status);
  },
  actionUpdateContractorName({commit}, contractor) {
    commit('updateContractorName', contractor);
  },
  updateMessage({commit}, message) {
    commit('updateMessage', message);
  },
  async handlerRefreshToken(context) {
    return // remove refresh token
  },
  checkRefreshToken(context) {
    const timeInterval = 300000; // 5 minute (300000) will check one time
    const token = webStorage.getToken();
    const timeExp = jwt_decode(token).exp;
    context.commit('updateTimeExp', timeExp);
    setInterval(() => {
      const currentdate = Date.now();
      const timeEnd = context.state.timeExp - currentdate / 1000;
      //  check time end < 30minute => call api refresh token 60*30
      if (timeEnd < 60 * 30 && context.state.authenticated) {
        context.dispatch('handlerRefreshToken');
      }
    }, timeInterval);
  },
  autoLogout(context) {
    const timeInterval = 300000; // 5 minute (300000) will check one time
    const token = webStorage.getToken();
    const timeExp = jwt_decode(token).exp;
    context.commit('updateTimeExp', timeExp);

    // setInterval(() => {
    //   let time = webStorage.get(CHECK_TIME_NOT_ACTIVE_KEY);
    //   if (time > 0) {
    //     // Refresh token
    //     if (timeExp < currentdate) {
    //       context.dispatch('handlerRefreshToken');
    //     }
    //     // after 5 minutes reduce the operation time 1 time
    //     webStorage.set(CHECK_TIME_NOT_ACTIVE_KEY, time - timeInterval);
    //   } else {
    //     webStorage.removeAll();
    //     window.location.href = '/auth/login';
    //   }
    // }, timeInterval);
    setInterval(() => {
      const currentdate = Date.now();
      const timeEnd = context.state.timeExp - currentdate / 1000;
      //  check time end < 30minute => call api refresh token 60*30
      if (timeEnd < 60 * 30 && context.state.authenticated) {
        context.dispatch('handlerRefreshToken');
      }
    }, timeInterval);
  },
  actionupdateContactForm({commit}, form) {
    commit('updateContactForm', form);
  },
  updateContractorData({commit}, contractor) {
    commit('updateContractorData', contractor);
  },
  updatePlanType({commit}, plan) {
    commit('updatePlanType', plan);
  },
  updateIsExistDbSource({commit}, existSource) {
    commit('updateIsExistDbSource', existSource);
  },
  getIsShowNotificationPcaf({ commit }, isShowNotiPcaf) {
    commit('getIsShowNotificationPcaf', isShowNotiPcaf);
  },
  updateIdDbSource({commit}, existSource) {
    commit('updateIdDbSource', existSource);
  },
  updateIsInProductPage({commit}, isInProductPage) {
    commit('updateIsInProductPage', isInProductPage);
  },
  updateMethodSetting({commit}, newVal) {
    commit('updateMethodSetting', newVal);
  },
  updateIsExistPcaf({commit}, newState) {
    commit('updateIsExistPcaf', newState);
  },
  updateLanguage({commit}, newVal) {
    commit('updateLanguage', newVal)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
