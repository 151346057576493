import request from './base/request';

import webStorage from '@/utils/webStorage';
import store from '@/store';
import { handleEncodeToken } from '@/utils/tokenHandle'

const loginApi = (data) => {
  return request
    .post('/login', data)
    .then((res) => {
      if (res.user.status) {
        webStorage.setToken(res.access_token, { domain: process.env.VUE_APP_COOKIE_DOMAIN || ".carbonix.ai" });
        const tokenEncode = handleEncodeToken(res.access_token)
        localStorage.setItem('chunkAuthObj', tokenEncode)
        store.dispatch('userData/updateInfoUser', { user: res.user }, { root: true });
        store.dispatch('userData/updateContractorSelect', res.user.contractor_id);
        store.dispatch('userData/actionUpdateAuthentication', true);
        store.dispatch('product/resetState', { root: true });
      }
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const logoutApi = () => {
  return request
    .get('/logout')
    .then(() => {
      store.dispatch('userData/resetState', { root: true });
      store.dispatch('userData/updateInfoUser', {}, { root: true });
      store.commit('userData/updateSelectContractor', null, { root: true });
      webStorage.removeAll();
      store.dispatch('dashboard/clearData');
      store.dispatch('registerData/updateDataMonth', null);
      store.dispatch('product/resetState', { root: true });
      store.dispatch('pcaf/resetState', { root: true });
      store.dispatch('reductionTarget/resetState', { root: true });
      store.dispatch('supplier/resetState', { root: true });
      localStorage.removeItem('chunkAuthObj')
      store.dispatch('registerData/clearData');
    })
    .catch(() => {
      store.dispatch('userData/resetState', { root: true });
      store.dispatch('userData/updateInfoUser', {}, { root: true });
      store.commit('userData/updateSelectContractor', null, { root: true });
      webStorage.removeAll();
      store.dispatch('dashboard/clearData');
      store.dispatch('product/resetState', { root: true });
      store.dispatch('pcaf/resetState', { root: true });
      store.dispatch('reductionTarget/resetState', { root: true });
      store.dispatch('supplier/resetState', { root: true });
      localStorage.removeItem('chunkAuthObj')
      store.dispatch('registerData/clearData');
    });
};

const forgotPasswordApi = (data) => {
  return request
    .post('/forgot-password', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};

const resetPasswordApi = (data) => {
  return request.post('/reset-password', data).catch((err) => {
    throw err;
  });
};

const checkResetPasswordTokenApi = (data) => {
  return request.post('/check-token-reset', data).catch((err) => {
    throw err;
  });
};

const changePasswordApi = (data) => {
  return request
    .patch('/change-password', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

const checkBlockAccApi = (data) => {
  return request
    .post('/check-block-account', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

const refreshToken = () => {
  return request
    .get('/refresh-token')
    .then((res) => {
      const tokenEncode = handleEncodeToken(res.access_token)
      localStorage.setItem('chunkAuthObj', tokenEncode)
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

const getUserInfo = () => {
  return request
    .get('/info')
    .then((res) => {
      if (!store.state.userData.currentUser.hasOwnProperty('user')) {
        let userData = {
          contractor: res?.contractor,
          contractor_id: res?.contractor_id,
          language: res?.language,
          role_id: res?.role_id,
          status: res?.role_id
        }
        store.dispatch('userData/updateInfoUser', { user: userData }, { root: true });
        store.dispatch('userData/updateContractorSelect', res?.contractor_id);
        store.dispatch('userData/actionUpdateAuthentication', true);
        store.dispatch('product/resetState', { root: true });
      }
      store.dispatch('userData/updatePlanType', res?.plan_type);
      store.dispatch('userData/updateContractorData', res.contractor);
      return res;
    })
    .catch(() => {});
};

const checkPassword = (password) => {
  return request
    .get('/check-password', { params: password })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const updateShowPopupNotificationPcaf = (params) => {
  return request
    .put('/update-show-notification-pcaf', params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const handleVerifyEmail = (params) => {
  return request
    .get('/verify-email', { params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const updateLastAction = (params) => {
  return request
    .post('/update-last-action', params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
}


export {
  loginApi,
  logoutApi,
  forgotPasswordApi,
  resetPasswordApi,
  changePasswordApi,
  checkBlockAccApi,
  refreshToken,
  getUserInfo,
  checkPassword,
  checkResetPasswordTokenApi,
  handleVerifyEmail,
  updateShowPopupNotificationPcaf,
  updateLastAction
};
