<template>
  <div class="category-table" :class="{'create-data-table': isCreatedDataStatus}" id="category-table">
    <wj-flex-grid-search ref="theSearch" placeholder="検索語句を入力" id="search-input">
      <v-btn class="search-btn">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </wj-flex-grid-search>
    <div
      v-if="isShowToolbar"
      id="grid-group"
      class="grid-group"
      :class="[
        isFullScreen && !isideaSoureDBScreen && 'grid-fullscreen',
        isOverText && 'is-over-text',
        isSettingScreen && 'grid-setting-screen',
        needCompress && 'compressed',
        isHasDataRegister && 'is-view-data',
        isideaSoureDBScreen && 'is-idea-source-screen',
        isPrimaryDataSource && 'is-primary-screen',
        isDisableOnReturnStatusPrimary && 'view-details-wf-returned',
        isOverTextMethods && 'is-over-text-methods']">
      <div class="duration-side fullmode-duration" v-if="isFullScreen && !isSettingScreen && !isideaSoureDBScreen && isShowDuration && !isPrimaryDataSource">
          <div class="year-select durations-pulldown-table" :class="{'disabled': isCreateNewData()}" v-if="!isEmissionViewScreen">
            <label class="durations-label">{{ $t("register_data.label_registration_date") }} </label>
            <div v-if="isCreateNewData()" class="duration-text disabled">
              <span>{{ getSelectedYearText() }}</span>
            </div>
            <select-item
              v-if="!isCreateNewData()"
              class="durations-select-item duration-header"
              :label="$t('b_list_all.table_registration_period')"
              :items="durationList"
              v-model="fullModeSelectYearChange"
              :selectEport= true
              :isFullScreens = "isFullScreens"
              :isHidePlaceholder="true"
            />
          </div>
          <div class="month-select durations-pulldown-table" :class="{'disabled': isCreateNewData()}" v-if="!isEmissionViewScreen">
            <v-select
              v-if="!isCreateNewData()"
              :items="monthYearSelectionList"
              item-text="text"
              item-value="value"
              v-model="fullModeSelectMonth"
              :label="getSelectLabel"
              :placeholder="getSelectLabel"
              hide-details
              dense
              solo
              no-data-text="''"
              class="durations-select-item select-item select-month new-register duration-header fullscreen-month-select"
              :disabled="isCreateNewData()"
              :menu-props="{ contentClass: 'selecting select-durations fullmode select-months' }"
            >
              <template slot="item" slot-scope="data">
                {{ data.item.text }}
                <v-spacer></v-spacer>
                <span v-if="data.item.isShowIcon" class="data-is-registered"></span>
              </template>
            </v-select>
            <div v-else class="duration-text disabled text-month"><span>{{ getSelectedMonthText() }}</span></div>
          </div>
          <div class="period-description" v-if="isEmissionViewScreen">
            <span class="title1">{{ $t('view_emission_update.title_period') }}</span>
            <span class="duration-date">{{ currentDuration }}</span>
          </div>
          <div class="method w-100 method-container" v-if="methodList.length > 1" :key="keySetting">
            <div v-if="!isShowSummaryScore" class="total-label method-label">{{ $t('register_data.label_calculation_method') }}</div>
            <common-button v-if="displayGetMethodName && !isEmissionViewScreen && !isShowSummaryScore" class="has-elipsis custom-btn-fullmode" @action="openSelectPopup" :label="getMethodName"></common-button>
            <div v-else-if="!isShowSummaryScore" class="method-name"><span>{{getMethodName}}</span></div>
            <div v-if="isShowSummaryScore" class="summary-score">
              <v-radio-group v-model="fullModeSelectSummaryScoreTypeChange" inline class="custome-radio-group full-screen-summary-score">
                <v-radio v-for="(item, i) in summaryScoreItems" :key="i" :label="`${item.value}`" :value="item.id" :ripple="false" />
              </v-radio-group>
            </div>
            <div v-if="isShowNotePcaf && !isShowSummaryScore" class="select-types full-screen">
              <div class="category-type" v-if="isShowCategorySelectPcaf">
                <div v-if="!displayGetMethodName || itemSelectPCAF.itemsCategoryType.length === 0" class="category-name"><span>{{ getCategoryName(itemSelectPCAF.itemsCategoryType, itemSelectPCAF.categoryIdSelect) }}</span></div>
                <v-select
                  v-else
                  :placeholder='$t("pcaf_update.placeholder_category_type_pcaf")'
                  :items="itemSelectPCAF.itemsCategoryType"
                  v-model="itemSelectPCAF.categoryIdSelect"
                  @change="(newValue) => onHandleChangeSelect('categoryIdSelect', newValue)"
                  item-text="name"
                  item-value="id"
                  dense
                  solo
                  class="select-item new-register"
                  :menu-props="{ contentClass: 'selecting select-duration-menu select-durations select-menu select-months' }"
                  :disabled="isEmissionViewScreen"
                ></v-select>
              </div>
              <div class="data-type">
                <div v-if="!displayGetMethodName|| itemSelectPCAF.itemsDataType.length === 0" class="category-name"><span>{{ getCategoryName(itemSelectPCAF.itemsDataType, itemSelectPCAF.dataTypeIdSelect) }}</span></div>
                <v-select
                  v-else
                  :placeholder='$t("pcaf_update.placeholder_data_type_pcaf")'
                  :items="itemSelectPCAF.itemsDataType"
                  v-model="itemSelectPCAF.dataTypeIdSelect"
                  @change="(newValue) => onHandleChangeSelect('dataTypeIdSelect', newValue)"
                  item-text="name"
                  item-value="id"
                  dense
                  solo
                  class="select-item new-register"
                  :menu-props="{ contentClass: 'selecting select-duration-menu select-durations select-menu select-months' }"
                  :disabled="isEmissionViewScreen"
                >
                </v-select>
              </div>
          </div>
          </div>
          <div class="total-result" :class="methodList.length === 1 && 'max-content'">
          <div class="emissions">
            <div class="total-label" v-if="$route.params.scope === '4'">{{ $t('register_data.label_emission_scope4')}}</div>
            <div class="total-label" v-else>{{ getSelectedMonthYearText() }}</div>
            <div class="total-emission" >{{ formatNumber(totalEmissions) || 0 }}<span class="label-unit">t-CO2</span></div>
          </div>
        </div>
      </div>
      <div class="top-side" :class="isHideUndoRedo && 'hide-undo-redo'">
      <div class="left">
        <div class="toolbar-fullscreen">
          <div class="fullscreen-item" :class="fullscreenWidth">
            <img src="@/assets/icons/icon-fullscreen.svg" alt="">
            <p class="mb-0 fullscreen-text">{{$t("register_data.button_display_on_full_screen")}}</p>
          <v-switch class="mt-0 pt-0 fullscreen-switch-btn" v-model="isFullScreens" color="#327998"></v-switch>
          </div>
        </div>
      </div>
      <div class="right">
        <undo-redo-comment-data-table v-if="!isHideUndoRedo" />
      </div>
      </div>
      <div v-if="isideaSoureDBScreen" class="db-idea-source">
        <v-select
          :items="dataIdeaDbSourceList"
          item-text="db_name"
          item-value="id"
          v-model="ideaDbIdModel"
          dense
          hide-details
          solo
          no-data-text="''"
          class="duration-header select-idea-source select-item"
          :menu-props="{ contentClass: `select-menu selecting ${selectIdeaDBClass}` }"
        >
          <template slot="item" slot-scope="data">
            {{ data.item.db_name }}
            <v-spacer></v-spacer>
          </template>
        </v-select>
      </div>
      <div class="comment-side" v-if="!hideHeaderRegisterData() && displayCommentOnDetails" >
        <comment-data-table
            @onHandleShowCommentHistory="onHandleShowCommentHistory"
            :totalComment="totalComment"
          />
      </div>
      <div class="mid-side" :class="!(!hideHeaderRegisterData() && displayCommentOnDetails) && 'not-comment-area'" v-if="!hideHeaderRegisterData()">
        <search-data-table
          v-if="!hideHeaderRegisterData()"
          :dataTitle.sync="dataTitle"
          :isReadOnly="isReadOnlyTitle"
      />
      </div>
      <div class="bottom-side" :class="{'height-fit-content': fullscreenWidth }" v-if="!isDisableOnReturnStatusPrimary">
        <action-header-table
          @onHandleActionTable="onHandleActionTable"
          @needCompressed="onNeedCompressed"
          @onHandleShowExportPopup="onHandleShowExportPopup"
          @onHandleGotoScoreSummary="onHandleGotoScoreSummary"
          @onNavigateToCreateDbSource="onNavigateToCreateDbSource"
          @onSendDataSupplier="onHandleSendDataSupplier"
          @onHandleShowError="onHandleShowError"
          :workFlowData="dataPattern && Object.keys(dataPattern).length ? dataPattern.workflow_data : {}"
          :isHasData="isHasData"
          :unCheckPermission="dataPattern && Object.keys(dataPattern).length ? dataPattern.unCheckPermission : false"
          :isDisableBySetting="isDisableBySetting"
          :isEditedData="isEditedData"
          :isDisableButtonStatusTemplate="isDisableButtonStatusTemplate"
          :isFullScreen="isFullScreen"
          :settingPcafBySelectMonth="settingPcafBySelectMonth"
          :isShowNotePcaf=isShowNotePcaf
        />
      </div>
    </div>
    <div id="temp-table">
      <div id="undoable-table">
        <wj-flex-grid
          id="FlexGrid"
          v-wjContextMenu="allowRightClick && 'ctxMenuId'"
          :isDisabled="isDisabled"
          ref="theGrid"
          class="wrap-table column-flexible"
          :style="getStyleTable"
          :allowDragging="false"
          :showMarquee="true"
          :frozenColumns="indexColumnFrozen"
          :initialized="initGrid"
          :itemsSource="dataSource"
          :allowAddNew="allowAddNew"
          :allowDelete="allowDelete"
          :columnGroups="isSetting ? gridColumns : null"
          :autoRowHeights="false"
          :allowResizing="'ColumnsAllCells'"
          keyActionTab="CycleOut"
          :allowMerging="'Cells'"
          showSelectedHeaders="All"
          :quickAutoSize="true"
          :isReadOnly="isReadOnly"
          :autoGenerateColumns="false"
          :imeEnabled=true
          :selectionChanged="changeSelectionCell"
          :loadedRows="loadedRows"
          :cellEditEnded="autoSizeColumn"
          :pasted="handlePasted"
          :bigCheckboxes="bigCheckboxes"
        >
          <wj-flex-grid-filter
            :filterColumns="filterColumns"
            :showSortButtons="true"
            :filterApplied="onFilterApplied"
            :filterChanged="onFilterChanged"
            :editingFilter="editingFilter"
          ></wj-flex-grid-filter>
        </wj-flex-grid>
        <wj-menu
            v-if="showMenu"
            id="ctxMenuId"
            ref="ctxMenu"
            selectedValuePath="cmd"
            style="display:none"
            header="Context Menu"
            dropDownCssClass="ctx-menu"
            :itemClicked="menuItemClicked"
            :initialized="menuInitialized">
            <wj-menu-item :cmd="1" :cmdParam="'1'">
                <span class="glyphicon glyphicon-asterisk"></span>&nbsp;&nbsp;{{ $t('right_clicked.copy') }}
            </wj-menu-item>
            <wj-menu-item :cmd="2" :cmdParam="'2'">
                <span class="glyphicon glyphicon-folder-open"></span>&nbsp;&nbsp;{{ $t('right_clicked.paste') }}
            </wj-menu-item>
            <wj-menu-item :cmd="insert" :cmdParam="canExcuteInsertFlg">
                <span class="glyphicon glyphicon-floppy-disk disabled"></span>&nbsp;&nbsp;{{ $t('right_clicked.insert') }}
            </wj-menu-item>
            <wj-menu-item :cmd="del" :cmdParam="canExcuteDelFlg">
                <span class="glyphicon glyphicon-remove"></span>&nbsp;&nbsp;{{ $t('right_clicked.delete') }}
            </wj-menu-item>
        </wj-menu>
        <div style="display:none">
          <wj-list-box class="column-picker" :initialized="initPicker"></wj-list-box>
        </div>
        <div v-if="isEmptySource" class="empty-process">
          <div class="empty-process-number">1</div>
          <div class="content">
            <img src="@/assets/images/dialog/notification.svg" alt="">
            <div class="label">{{ labelEmpty }}</div>
          </div>
        </div>
      </div>
    </div>
    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
    <LoadingFullTable v-if="isLoadingTableOnly || (isLoadingPopUp && tableName ==='searchExternalSource')" />
  </div>
</template>

<script>
import "@mescius/wijmo.styles/wijmo.css";
import "@mescius/wijmo.vue2.grid";
import "@mescius/wijmo.vue2.grid.filter";
import "@mescius/wijmo.vue2.grid.detail";
import '@mescius/wijmo.vue2.input';
import { mapActions, mapState } from "vuex";
import "@mescius/wijmo.vue2.grid.search";
import * as wjcCore from "@mescius/wijmo";
import * as wjcGrid from "@mescius/wijmo.grid";
import ButtonCustom from "@/components/commonApp/ButtonCustom";
import ActionHeaderTable from "@/components/commonApp/ActionTable";
import SearchDataTable from "@/components/commonApp/ActionTable/SearchDataTable";
import UndoRedoCommentDataTable from "@/components/commonApp/ActionTable/UndoRedoCommentDataTable";
import CommentDataTable from "@/components/commonApp/ActionTable/CommentDataTable";
import CommonButton from '@/components/utils/button.vue';
import SelectItem from '@/components/utils/selectItem.vue';
import { ROUTES } from '@/router/constants';
import LoadingFullTable from "@/components/commonApp/LoadingFullTable.vue";

import CommentLogPopup from '@/components/dialogs/log-confirm-popup';
import { getListCommentHistory } from '@/api/registerData';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat'
import { convertMonthCalendar } from '@/utils/registerData'
import { $_helper_isNumberType, formatBigNumber, formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import {addThoundSandComma, addThoundSandCommaWithTwoDigit} from '@/utils/convertNumber';
import { getClassesFullScreenButton } from '@/utils/multiLanguage';
import { STATUS_FIELD } from '@/constants/status';
import { ACTION_CODE } from '@/constants/rightClickAction.js';
import { listAllDbIdea } from '@/api/ideaDbSource';
import { prepareMasterOrCustomizeDB, makeNewId } from '@/concerns/utils/master-or-customize-db';
import { itemKeyHasFormatNumber } from '@/constants/registerData';
import { convertNestedFullWidthToHalfWidth } from '@/utils/convertHalfWidth';
import { BLANK_ID } from '@/constants/registerData'
import { EMAIL_VALID } from '@/constants/status';
import { dbServiceLinkBySourceName } from '@/api/serviceLink';
import { getDbRelationKeysByType } from '@/utils/sourceExternal';

export default {
  data() {
    return {
      isFullScreens: false,
      startIndex: 0,
      endIndex: 0,
      sourceCollection: null,
      theSearch: null,
      theGrid: null,
      dataTable: null,
      idLast: null,
      currentSelection: null,
      flexGrid: null,
      selectionMode: null,
      windowWidth: window.innerWidth,
      isClickFilter: false,
      dataTitle: null,
      commentPopup    : false,
      commentHistoryList: [],
      totalComment: 0,
      needCompress: false,
      listWidth: [],
      listColumns: [],
      heightToolBar: 0,
      fullscreenWidth: getClassesFullScreenButton(),
      isOverTextMethods: false,
      isOverText: null,
      ctxMenuEl: null,
      ctxMenuControl: null,
      insert: {
        canExecuteCommand: (arg) => {
            return arg;
        },
      },
      del: {
        canExecuteCommand: (arg) => {
            return arg;
        },
      },
      idDbExternal: false
    };
  },
  components: {
    ButtonCustom,
    ActionHeaderTable,
    SearchDataTable,
    UndoRedoCommentDataTable,
    CommentDataTable,
    CommentLogPopup,
    SelectItem,
    CommonButton,
    LoadingFullTable
  },
  props: {
    totalEmissions: {
      type: [String, Number],
      default: 0,
    },
    selectedYear: {
      default: null,
    },
    summaryScoreType: {
      default: null,
    },
    selectedMonth: {
      default: null,
    },
    methodList: {
      type: Array,
      default: () => []
    },
    keySetting: {
      type: Number,
      default: 0
    },
    displayGetMethodName: {
      type: Boolean,
      default: true
    },
    allowRightClick: {
      type: Boolean,
      default: false
    },
    isCreatedDataStatus: {
      type: Boolean,
      default: false
    },
    getMethodName: {
      type: String,
      default: '',
    },
    isCreateNewData: {
      type: Function,
      default: () => {
      }
    },
    indexColumnFrozen: {
      type: Number,
      default: 0
    },
    isGetAllData: {
      type: Boolean,
      default: true
    },
    durationList: {
      type: Array,
      default: () => []
    },
    summaryScoreItems: {
      type: Array,
      default: () => []
    },
    monthYearSelectionList: {
      type: Array,
      default: () => []
    },
    selectedDurationText: {
      type: String,
      default: '',
    },
    selectedMonthText: {
      type: String,
      default: '',
    },
    dataPattern: {
      type: Object,
      default: () => {
      }
    },
    dataSource: {
      type: Object,
      default: () => {
      }
    },
    tableName: {
      type: String,
      default: '',
    },
    initGrid: {
      type: Function,
      default: () => {
      }
    },
    initPicker: {
      type: Function,
      default: () => {
      }
    },
    gridColumns: {
      type: Array,
      default: () => []
    },
    filterColumns: {
      type: Array,
      default: () => []
    },
    allowDelete: {
      type: Boolean,
      default: false
    },
    allowAddNew: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    totalData: {
      type: Number,
      default: 0
    },
    rowFocus: {
      type: Number,
      default: 0
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    showFocus: {
      type: Boolean,
      default: true
    },
    lastedRow: {
      type: Object,
      default: () => {
      }
    },
    isSetting: {
      type: Boolean,
      default: false
    },
    isHasData: {
      type: Boolean,
      default: false
    },
    isShowToolbar: {
      type: Boolean,
      default: true
    },
    isPermissionProductScreen: {
      type: Boolean,
      default: false
    },
    isEditedData: {
      type: [Number, Boolean],
      default: 0
    },
    isReadOnlyTitle: {
      type: Boolean,
      default: false
    },
    pattern: {
      type: Object,
      default: () => {}
    },
    itemsInView: {
      type: Array,
      default: () => []
    },
    cellInput: {
      type: Array,
      default: () => []
    },
    isResizeCustome: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    isEmptySource: {
      type: Boolean,
      default: false
    },
    labelEmpty: {
      type: String,
      default: ""
    },
    isDisableBySetting: {
      type: Boolean,
      default: false
    },
    isHideUndoRedo: {
      type: Boolean,
      default: false
    },
    dataIdeaDbSourceList: {
      type: Array,
      default: () => []
    },
    ideaDbId: {
      type: Number,
      default: null,
    },
    isDisableButtonStatusTemplate: {
      type: Boolean,
      default: false
    },
    flexgridProps: {
      type: Object,
      default: () => {}
    },
    dbStore: {
      type: Object,
      default: () => {}
    },
    isShowDuration: {
      type: Boolean,
      default: true
    },
    isApproval: {
      type: Boolean,
      default: false
    },
    settingPcafBySelectMonth: {
      type: Boolean,
      default: false,
    },
    isShowNotePcaf: {
      type: Boolean,
      default: false
    },
    isShowSummaryScore: {
      type: Boolean,
      default: false
    },
    isShowCategorySelectPcaf: {
      type: Boolean,
      default: false
    },
    itemSelectPCAF: {
      type: Object,
      default: () => {

      }
    },
    currentDuration: {
      type: null,
      default: null,
    },
    isEmissionViewScreen: {
      type: Boolean,
      default: false
    },
    bigCheckboxes: {
      type: Boolean,
      default: false
    },
    isRemoveIndex: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    await import(`@mescius/wijmo.cultures/wijmo.culture.${this.$i18n.locale}`).then(res => {
      wijmo.culture.FlexGridFilter.null = this.$t("register_data.checkbox_blank");
    })
  },
  mounted() {
    this.theGrid = wjcCore.Control.getControl(this.$refs.theGrid.$el);
    this.$emit("flexGridInitialDone", this.theGrid);
    this.removeMarqueeBorder();
    this.theSearch = wjcCore.Control.getControl(this.$refs.theSearch.$el);
    this.actionRemoveListOfEditedCells([]);
    this.theSearch.grid = this.theGrid;
    // focus invalidate cell
    this.theGrid.validateEdits = false;
    this.setRowIndex(this.theSearch.grid);
    this.actionStartUpdate({
      undo: false,
      redo: false
    });
    this.actionUpdateStatusBtn({
      undo: false,
      redo: false
    });
    this.updateActionDelete(false);
    this.actionUpdateDataAction({});
    this.actionUpdate({});
    this.actionUpdateFilter(false);
    this.isFullScreens = this.isFullScreen;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.hideHeaderRegisterData();
    this.dataTitle = this.dataPattern?.workflow_data?.title || sessionStorage.getItem("title");
    this.totalComment = this.dataPattern?.workflow_data?.comment_count || 0;
    this.isOverTextMethods = this.getWidth();
    window.addEventListener('resize', this.handlerCalcHeight);
    this.isOverText = this.getWidthAllActionTable();
    this.ctxMenuEl = document.getElementById('ctxMenuId');
  },
  watch: {

    isFullScreens: function(newState) {
      // waiting DOM updated => calc height
      this.$nextTick(() => {
        this.handlerCalcHeight()
      });


      this.actionUpdateIsFullScreen(newState);
      this.$emit("changeFullScreen", newState);
    },
    dataSource: function() {
      this.setRowIndex(this.theSearch.grid);
    },

    isError: function() {
      if (!this.isError) {
        this.setRowIndex(this.theSearch.grid);
      }
    },

    isFullScreen: function(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.isFullScreens = this.isFullScreen;
        setTimeout(() => {
          this.handleFocus()
        }, 100)
      }
    },

    isEditedData() {
      this.updateIsEditData(this.isEditedData)
    },
    dataTitle() {
      this.$emit('updateDatatitle', this.dataTitle)
    }
  },
  computed: {
    ...mapState("commonApp", ["loadMore", "isFilter", "isLoadingTableOnly", "isLoadingPopUp"]),
    ...mapState("registerData", ["listOfEditedCells", "isError", "isFullScreen"]),
    canExcuteInsertFlg() {
      // No need to check by role, it depend on isReadOnly
      if(this.dataPattern?.workflow_data?.status ===  STATUS_FIELD.STATUS_APPROVED || this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED || this.$route.fullPath.includes('/emissions/view/')) {
        return false
      }
      return ACTION_CODE.INSERT;
    },
    isDisableOnReturnStatusPrimary() {
      return (this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_RETURN || this.dataPattern?.workflow_data?.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN) && this.$route.fullPath.includes('/emissions/register/primary-data')
    },
    canExcuteDelFlg() {
      const disableDeleteInRoute = [
        ROUTES.USER_MANAGEMENT,
        ROUTES.FACILITY_MANAGEMENT,
        ROUTES.CREATE_DATA_CUSTOMIZE,
        ROUTES.PRODUCTS_EMISSION + ROUTES.USER_MANAGEMENT,
        ROUTES.PRODUCTS_EMISSION + ROUTES.FACILITY_MANAGEMENT,
        ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE,
      ];
      if(disableDeleteInRoute.includes(this.$route.path)) {
        return false;
      }
      return ACTION_CODE.DELETE;
    },
    showMenu() {
      return this.theGrid && this.theGrid.selection.row >= 0;
    },
    isideaSoureDBScreen() {
      return this.$route.path === ROUTES.LIST_IDEA_DB_SOURCE;
    },
    isPrimaryDataSource() {
      return this.$route.path === ROUTES.PRIMARY_DATA_MERGE || this.$route.path === ROUTES.FORM_PRIMARY_DATA_MERGE; 
    },
    isHasDataRegister() {
      // Handle condition to display dot
      return this.$route.fullPath.includes('/emissions/view/');
    },
    getSelectLabel() {
      return this.$route.params?.scope === ROUTES.LIST_EMISSION_TEMPLATES ? '' : this.$t("emissions_detail.placeholder_please_select");
    },
    fullModeSelectMonth: {
      get() {
        return this.selectedMonth;
      },
      set(newOption) {
        this.$emit("update:value", newOption);
      }
    },
    fullModeSelectYearChange: {
      get() {
        return this.selectedYear;
      },
      set(newOption) {
        this.$emit("update:input", newOption);
      }
    },
    fullModeSelectSummaryScoreTypeChange: {
      get() {
        return this.summaryScoreType;
      },
      set(newOption) {
        this.$emit("update:enterValue", newOption);
      }
    },
    ideaDbIdModel: {
      get() {
        return this.ideaDbId;
      },
      set(newOption) {
        this.$emit("updateValueIdeaId", newOption);
      }
    },
    isSettingScreen() {
      return [
        ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_CATEGORY,
        ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_PERIOD,
        ROUTES.USER_MANAGEMENT,
        ROUTES.GROUPS_MANAGEMENT,
        ROUTES.PRODUCTS_EMISSION + ROUTES.GROUPS_MANAGEMENT,
        ROUTES.VIEWER_MANAGEMENT,
        ROUTES.FACILITY_MANAGEMENT,
        ROUTES.GROUP_ENTERPRISE_MANAGEMENT,
        ROUTES.CREATE_DATA_CUSTOMIZE,
        ROUTES.SETTING_TARGET,
        ROUTES.PRODUCTS_EMISSION + ROUTES.USER_MANAGEMENT,
        ROUTES.PRODUCTS_EMISSION + ROUTES.FACILITY_MANAGEMENT,
        ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE,
        ROUTES.PRODUCTS_EMISSION + ROUTES.VIEWER_MANAGEMENT,].includes(this.$route.path);
    },
    getStyleTable() {
      if(!this.isFullScreens){
        return ''
      } else {
        return `max-height: calc(100vh - (88px + ${this.heightToolBar}px)) !important`
      }
    },
    displayCommentOnDetails() {
        return [ROUTES.LIST_EMISSION_SUBMITTED, ROUTES.LIST_APPROVAL, ROUTES.LIST_MENU_EMISSION, ROUTES.PRIMARY_DATA_MERGE_DETAIL].includes(
          this.$route.params?.scope,
        ) || (this.$route.path === ROUTES.PRODUCTS_EMISSION + '/' + ROUTES.PRODUCTS_NEW_REGISTER && this.$route.query?.type !== 'template');
    },
    selectIdeaDBClass() {
      return this.dataIdeaDbSourceList?.length > 4 ? 'select-idea-db' : ' select-idea-db item-scroll-none';
    }
  },
  methods: {
    ...mapActions("commonApp", ["loadMoreAction", "actionUpdateFilter", "actionUpdateIsLoadingTable"]),
    ...mapActions("actionsTable", ["actionStartUpdate"]),
    ...mapActions("actionsTable", ["actionUpdateDataAction"]),
    ...mapActions("actionsTable", ["actionUpdate", "actionUpdateStatusBtn", "updateActionDelete"]),
    ...mapActions("registerData", ["actionUpdateIsFullScreen", "actionRemoveListOfEditedCells"]),
    ...mapActions('newRegisterData', ['updateIsEditData']),
    menuItemClicked: function(menu) {
      switch (Number(menu.selectedItem.cmdParam)) {
      case ACTION_CODE.COPY:
        this.actionCopyCellImput();
        break;
      case ACTION_CODE.PASTE:
        this.actionPasteTheCopiedContent()
        break;
      case ACTION_CODE.INSERT:
        this.actionInsertRow();
        break;
      case ACTION_CODE.DELETE:
        this.actionDeleteRow();
        break;
      default:
        break;
      }
    },
    actionCopyCellImput() {
      let selection = this.theGrid.selection;
      if (selection.isValid) {
            // Get the selected data
            let selectedData = [];
            for (let row = selection.topRow; row <= selection.bottomRow; row++) {
                let rowData = [];
                for (let col = selection.leftCol > 0 ? selection.leftCol : 1; col <= selection.rightCol; col++) {
                    rowData.push(this.theGrid.getCellData(row, col, true));
                }
                selectedData.push(rowData);
            }

            let clipboardData = selectedData.map(row => row.join('\t')).join('\n');
            
            wjcCore.Clipboard.copy(clipboardData);
        }
    },
    getDataSourceIdByNameSource(binding, nameSource, newDataAdded, dbIdeaBySourceName) { //todo: update for new idea db
      let dbItem = {}
      this.idDbExternal = false;
      switch (binding) {
        case 'wsu_source':
          dbItem = this.dbStore.getDbCustomizeOrDbMasterByWsuSource[nameSource]
          break;
        case 'energy_type':
        case 'fuel':
        case 'ides_industry':
        if(this.settingPcafBySelectMonth) {
            dbItem = this.dbStore.getDbCustomizeOrDbMasterByWsuSource[nameSource]
          } else {
            dbItem = this.dbStore.getDbCustomizeOrDbMasterByItemName[nameSource]
          }
          break;
        default:
          break;
      }

      let isIdeaIsUndefined = false;
      //check idea if dbItem is undefined
      if(!dbItem) {
        dbItem = dbIdeaBySourceName[nameSource] || dbServiceLinkBySourceName[nameSource];
        if(dbItem) {
          this.$emit('addValueSourceIntoGrid', dbItem)
          isIdeaIsUndefined = true;
        }
      }
      const dbRelationKey = getDbRelationKeysByType(dbItem.type);
      const newId = makeNewId(dbItem?.origin_id, dbItem.type)
      newDataAdded.idDbExternal = newId
      newDataAdded.db_relation = {
        [dbRelationKey]: dbItem?.origin_id
      }
      this.idDbExternal = true;
      return isIdeaIsUndefined ? newId : dbItem?.id;
    },
    actionPasteTheCopiedContent() {
      this.actionUpdateIsLoadingTable(true)
      const isUsersSetting = this.tableName === 'usersSetting' || this.tableName === 'db-customize';
      navigator.clipboard.readText()
      .then(clipboardData => {
        let rows = clipboardData.split('\n');
        if (rows.length > 0 && rows[rows.length - 1] === '') {
          rows.pop();
        }
        const dbIdeaBySourceName = {}
        prepareMasterOrCustomizeDB(listAllDbIdea).forEach((item) => {
          if (!dbIdeaBySourceName[item.source]) {
            dbIdeaBySourceName[item.source] = {...item, 'isExternalSource' : true}
          }
        });

        for (let i = 0; i < rows.length; i++) {
          let cols = rows[i].split('\t');
          const existRow = this.dataSource.sourceCollection[this.theGrid.selection.topRow + i];
          if (!existRow) {
            var addnewData = this.dataSource.addNew();
          }
          for (let j = 0; j < cols.length; j++) {
            let leftColumn = this.theGrid.selection.leftCol > 0 ? this.theGrid.selection.leftCol : 1

            const binding = this.theGrid.getColumn(leftColumn + j)?.binding
            // Remove data paste for users table(password and status)
            if(isUsersSetting && (binding === 'initial_password' || binding === 'valid_email' || binding === 'updated_at')) {
              continue;
            }
            if(!binding) {
              continue;
            }
            if(this.tableName === 'db-customize' && existRow && existRow?.pattern_type) {
              continue;
            }
            
            if(this.tableName === 'usersSetting') {
              if (
                binding === 'email' &&
                existRow.valid_email !== this.$t(EMAIL_VALID.INVALID_EMAIL) &&
                existRow.id &&
                existRow.id !== BLANK_ID
              ) {
                continue
              }
            }

            const formatColData = cols[j].replace(/(\r\n|\n|\r)/gm, "")
            let cellValue = formatColData
            // chuyển đổi data cols[j] thành uniqueID nếu binding là cột db
            if(this.pattern?.valueSource && binding === this.pattern?.valueSource) {
              cellValue = this.getDataSourceIdByNameSource(binding, formatColData, !existRow ? addnewData : existRow, dbIdeaBySourceName)
            }

            if(itemKeyHasFormatNumber.includes(binding)) {
              const convertValue = convertNestedFullWidthToHalfWidth(cellValue)
              const isNumber = $_helper_isNumberType(convertValue);
              const range = this.getSubstringRange(binding);
              const subStringRang = convertValue?.toString()?.includes('-') ? range + 1 : range;
              const valueFormat = binding === 'emissions_factor' ? convertValue : addThoundSandComma(formatValue(convertValue)?.substring(0, subStringRang))
              cellValue = isNumber ? valueFormat : ''
              if(existRow) {
                existRow.emissions = 1 //fake emissions for calc emission handle
              } else {
                addnewData.emissions = 1
              }
            }

            if (!existRow) {
              addnewData[this.theGrid.getColumn(leftColumn + j).binding] = cellValue;
              if(['organizational_division', 'wsu_type'].includes(binding)) {
                this.$emit('changeTextToIdInSpecialPattern', addnewData, binding, cellValue)
              }
            } else {
              const rowData = this.dataSource.sourceCollection[this.theGrid.selection.topRow + i]
              const canPasteData = rowData?.status && !rowData?.is_primary_data
              if (binding === 'status') {
                this.theGrid.setCellData(this.theGrid.selection.topRow + i, leftColumn + j, cellValue);
              }
              if (canPasteData || !this.isApproval) {
                this.theGrid.setCellData(this.theGrid.selection.topRow + i, leftColumn + j, cellValue);
                this.$emit('updateAutoFill', existRow)
              }
              if(!this.idDbExternal) {
                existRow.idDbExternal = null
              }
            }
          }
          if (!existRow) {
            this.dataSource.commitNew();
            this.dataSource.refresh();
          }
        }
        this.$emit('checkTableHasData')
        setTimeout(() => {
          this.actionUpdateIsLoadingTable(false)
        }, 50)
      })
      .catch(error => {
          this.actionUpdateIsLoadingTable(false)
      });
    },
    getSubstringRange(columnName){
      return ['fuel_efficiency', 'loading_data_rate', 'pj_investment_ratio'].includes(columnName) ? 10: 25
    },
    actionInsertRow() {
      let addData = {
        orderId: Math.random(),
        id: BLANK_ID
      };

      this.dataSource.sourceCollection.splice(this.theGrid.selection.topRow, 0, addData);

      if (this.dataSource.trackChanges) {
          this.dataSource.itemsAdded.push(addData);
      }
      if(this.dataSource.itemsEdited[0]) {
        this.dataSource.itemsEdited[0].isAddedByClick = true
      }
      this.dataSource.refresh();
    },
    actionDeleteRow() {
      var selectedItems = this.theGrid.selectedItems;

      // Check if any rows are selected
      if (selectedItems.length > 0) {
          const dataSource = this.dataSource;
          selectedItems.forEach(function (item) {
            dataSource.remove(item)
            dataSource.commitNew();
          });
          this.$emit("actionRightClick", ACTION_CODE.DELETE)
      } else {
          console.warn("No rows selected.");
      }
    },
    menuInitialized: function(menu) {
        this.ctxMenuControl = menu;
    },
    onNeedCompressed(needCompress){
      this.needCompress= needCompress;
    },
    getWidthByTextContent(string, isHeader = false, isMethodText = false, isMethodTextNormal = false){
      let padding = 23
      let text = document.createElement("span");
      document.body.appendChild(text);
      text.style.width = 'auto';
      text.style.position = 'absolute';
      text.style.whiteSpace = 'no-wrap';
      text.style.font = "Source Han Sans";
      text.style.fontSize = isHeader ? 13 : 14 + "px";
      if(isHeader) {
        text.style.fontWeight = "500";
      }
      if(isMethodText) {
        text.style.fontWeight = "700";
        text.style.fontSize = "11px";
        text.style.lineHeight = '18px'
        text.style.letterSpacing = '0.33px'
        padding = 1;
      }
      if(isMethodTextNormal) {
        text.style.fontWeight = "500";
        text.style.fontSize = "14px";
        text.style.lineHeight = '24px'
        text.style.letterSpacing = '0.42px'
        padding = 34;
      }
      text.innerHTML = string;
      const width = text.clientWidth;
      document.body.removeChild(text);
      return width + padding;
    },
    getWidth() {
      if(window.innerWidth < 1024) {
        let labelMethod = this.getWidthByTextContent(this.$t('register_data.label_calculation_method'), false, true);
        let textMethodName = this.getWidthByTextContent(this.getMethodName, false, true);
        //8 padding label + method name
        // 8 * 2 padding method name
        if(labelMethod + textMethodName + 8 + 16*2 > window.innerWidth - (79 *2 + 8 * 2 + 20*2)) {
          return true;
        }
      }
      return false
    },
    getWidthAllActionTable() {
      let topSide = document.getElementsByClassName("top-side")[0]?.offsetWidth + 18;
      let commentSide = document.getElementsByClassName("comment-side")[0]?.offsetWidth || 0;
      let bottomSide = document.getElementsByClassName("bottom-side")[0]?.offsetWidth || 0;
      let totalWidth = topSide + 8 + 8 + commentSide + 40 + bottomSide;
      let methodContainer = this.getWidthByTextContent(this.getMethodName, false, false, true);
      if (window.innerWidth - methodContainer < 590 && window.innerWidth > 1023) {
        this.$emit("onChangeStyleMethodFlex", true);
        this.$emit("onChangeStyleActionTable", false);
      } else if (window.innerWidth - methodContainer < 768 && window.innerWidth - methodContainer >= 590 && window.innerWidth > 1023) {
        this.$emit("onChangeStyleActionTable", true);
        this.$emit("onChangeStyleMethodFlex", false);
      } else {
        this.$emit("onChangeStyleActionTable", false);
        this.$emit("onChangeStyleMethodFlex", false);
      }
      if(window.innerWidth - totalWidth < 337) {
        return true;
      }
    },
    hideHeaderRegisterData() {
      return this.$route.name !== "NewRegisterData" && this.$route.name !== "Reduction Target";
    },
    onNavigateToCreateDbSource() {
      this.$emit('onNavigateToCreateDbSource');
    },
    onHandleSendDataSupplier() {
      this.$emit('onSendDataSupplier');
    },
    async onHandleShowCommentHistory() {
      this
      if(Object.keys(this.dataPattern).length) {
        const payload = {
        id: this.dataPattern.workflow_data.id
      }
      const commentList = await getListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map(commentHistory => {
          return {
            ...commentHistory,
            date: formatDateTimeDataTable(commentHistory.created_at),
            name: commentHistory.user.name,
            content: commentHistory.content,
          }
        })
      }
      this.commentPopup = true;
    },
    onHandleActionTable(action, checkHasUserAccessTemplateDetail) {
      this.$emit("onHandleActionTable", action, this.dataTitle, checkHasUserAccessTemplateDetail);
    },
    setWidthForColumns(theGrid, index, width) {
      theGrid.columnHeaders.columns[index].width = width;
      theGrid.columnHeaders.columns[index].minWidth = width;
    },
    getMaxWidthByContent(columns) {
      let resultArrayWidth = [];
      columns.forEach((column) => {
        let indexMaxLengthOfColumn = this.dataSource._view.reduce((indexMaxLengthOfColumn, rowData, index) => {
          return rowData?.[column.binding]?.toString()?.length > this.dataSource._view[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length ? index : indexMaxLengthOfColumn
        }, 0);

        const widthOfCellMaxlengh = this.getWidthByTextContent(this.dataSource._view[indexMaxLengthOfColumn]?.[column.binding])
        resultArrayWidth.push(widthOfCellMaxlengh)
      })
      return resultArrayWidth
    },
    getSelectedYearText() {
      return this.$t('register_data.year_calendar_select', {year: this.selectedYear});
    },
    getSelectedMonthText() {
      return this.$t('register_data.month_select', { month: convertMonthCalendar(this.selectedMonth)});
    },
    // implement
    getSelectedMonthYearText() {
      if (this.isEmissionViewScreen) return
      if (this.selectedMonth && this.selectedYear) {
      return this.$t('emissions_detail.label_total_emissions_month_year', {year: this.selectedYear, month: convertMonthCalendar(this.selectedMonth)});
      }
      return this.$t('emissions_detail.label_total_emissions_month_year_empty');
    },
    formatNumber(num) {
      // return formatBigNumber(num).replace(/[^0-9\.\-]+/g, ''); // replace "" to null; example "0" -> 0;
      return addThoundSandCommaWithTwoDigit(formatBigNumber(num, 35).replace(/[^0-9\.\-]+/g, ''), '', false, true);
    },
    handleResizeAll(theGrid) {
      return
    },
    onHandleShowExportPopup(value) {
      this.$emit('onHandleShowExportPopup', value);
    },
    onHandleGotoScoreSummary(value) {
      this.$emit('onHandleGotoScoreSummary', value);
    },
    openSelectPopup() {
      this.$emit('openSelectPopup');
    },
    loadedRows: function(theGrid) {
      return

    },
    resetAutoSizingGrid(theGrid) {
      return
    },
    autoSizeColumn: function(theGrid) {
      return
    },
    handlePasted(theGrid, event) {
      return
    },
    changeSelectionCell() {
      this.$emit("onChangeSelected");
    },
    getRowFocusRegisterPage() {
      if (typeof this.pattern.cellInputInView(this.itemsInView) === 'object') {
        const DEFAULT_INDEX = -1;
        const FIRST_INDEX = 0;
        const itemsInView = this.pattern.cellInputInView(this.itemsInView);
        let curentIndex = DEFAULT_INDEX;
        this.dataSource._view.some((item, index) => {
          const everyColumnsAreNull = itemsInView.every(
            (field) => item[field] === null || item[field] === '' || item[field] === undefined,
          );
          if (!everyColumnsAreNull) {
            curentIndex = index
          }
        });
        return curentIndex === DEFAULT_INDEX ? FIRST_INDEX : curentIndex + 1  // +1 for next row
      } else {
        return 0
      }
    },
    getRowFocusListSettingPage() {
      const DEFAULT_INDEX = -1;
      const FIRST_INDEX = 0;
      let curentIndex = DEFAULT_INDEX;
      this.dataSource._view.some((item, index) => {
        const everyColumnsAreNull = this.cellInput.every(
          (field) => item[field] === null || item[field] === '' || item[field] === undefined,
        );
        if (!everyColumnsAreNull) {
          curentIndex = index
        }
      });
      return curentIndex === DEFAULT_INDEX ? FIRST_INDEX : curentIndex + 1  // +1 for next row
    },
    handleFocus() {
      let index = 0;
      let exists = false;
      while (this.dataSource.itemCount > index && !exists) {
        if (this.dataSource.items[index].id === "Blank") {
          exists = true;
        } else {
          index++;
        }
      }

      const listNameRegisterDataRoute = ['NewRegisterData', 'RegisterData']
      const curentIndexFocus = listNameRegisterDataRoute.includes(this.$route.name) ? this.getRowFocusRegisterPage() : this.getRowFocusListSettingPage();
      this.theGrid.selection = new wjcGrid.CellRange(curentIndexFocus, this.rowFocus);
      this.theGrid.startEditing(true, curentIndexFocus, this.rowFocus);
      return
    },

    scrollTo(index) {
      // get cell rect, adjust scrollPosition.y to bring it to the top
      let rc = this.theGrid.cells.getCellBoundingRect(index, 0, true);
      this.theGrid.scrollPosition = new wjcCore.Point(this.theGrid.scrollPosition.x, -rc.top);
    },

    onFilterApplied(s, _) {
      let isFiltering = !s._filters.every((column) => !column.isActive);
      this.actionUpdateFilter(isFiltering);
      if (this.isClickFilter) {
        this.theGrid.scrollIntoView(0, -1); // scroll to first row
      }
    },

    setRowIndex(s) {
      if(this.isRemoveIndex) return
      s.itemFormatter = (panel, r, c, cell) => {
        if (panel.cellType === wjcGrid.CellType.RowHeader && !this.isFilter) {
          cell.textContent = (r + 1 + ((this.currentPage - 1 ) * 20)).toString();
          cell.draggable = true;
        }
        if (panel.cellType === wjcGrid.CellType.RowHeader && this.isFilter) {
          cell.style.justifyContent = "center";
          cell.textContent = (r + 1 + ((this.currentPage - 1 ) * 20)).toString();
        }
      };
    },

    onFilterChanged(s, e) {
      if (this.theGrid?.collectionView !== null) {
        this.isClickFilter = true;
      }
    },
    handlerCalcHeight() {
      this.isOverTextMethods = this.getWidth();
      this.isOverText = this.getWidthAllActionTable();
      const element = document.getElementById('grid-group');
      this.heightToolBar = element.offsetHeight - 5; // 5 is top css
    },
    editingFilter(s, e) {
      this.$emit('onHandlerSelecteFilterColumn', e.getColumn().binding);
      let valueFilter = s.getColumnFilter(e.getColumn().binding, true).valueFilter;
      if(this.tableName === "reductionTarget") {
        const mergeRowValueFilter = valueFilter.getUniqueValues().map(item => item.value).filter(i => i !== null);
        valueFilter.uniqueValues = mergeRowValueFilter;
      }
      valueFilter.maxValues = 10000;
      const formatItems = [
        'value', 'loading_data_maximum', 'loading_data_rate',
        'distance', 'fuel_efficiency', 'qd_value', 'energy_saving_value', 'distance_value',
        'scope12_overlap', 'industrial_waste_value', 'passengers', 'travel_value', 'nights', 'business_trip_days',
        'employees_number_value', 'te_value', 'working_days_number_value', 'iss_value', 'sales_volume_value',
        'times_use', 'sold_number', 'feedstock_value', 'ghg_content_value', 'total_products_sold', 'ghg_rate', 'scale_index_value',
        'ie_company_value', 'ir_release_quantity', 'ir_company_hold', 'ir_owned', 'le_value', 'pj_investment_ratio', 'ir_total_investment',
        'ir_internal_investment', 'ir_investment_on_total', 'ir_calculated_denominator', 'ir_calculation_numerator', 'ir_ratio', 'aoa_value',
        'transport_weight_value', 'sea_distance_value', 'cpa_value', 'energy_value', 'dir_value','wsu_value', 'le_value','pj_investment_ratio','emissions',
        'value_source', 'category_id', 'total_emission_branch', 'rate_all', 'emission_total_product', 'emission_per_product', 'category', 'il_amount', 'il_total_amount', 'ie_ghg_emissions',
        'dq_quality_score', 'revenue', 'purchase_level', 'amount', 'total_capital', 'value_number_1', 'value_number_2', 'loan_amount', 'value_purchase', 'emissions_factor', 'value_number'
      ];
      if (!formatItems.includes(e.getColumn().binding)) return;
      let vals = [];
      let isCategory16 = false;
      const category16Text = this.$t("list_menu.title_scope_others");
      const categoryText = this.$t("dashboard_main.label_category");
      let isNullText = false;
      const nullText = this.$t("register_data.checkbox_blank");
      valueFilter.uniqueValues = null;
      valueFilter.getUniqueValues().forEach(item => {
        if (e.getColumn().binding === 'rate_all') {
          const value = item.text.split('%')[0]?.trim();
          if ($_helper_isNumberType(value)) {
            vals.push(value);
          }
        } else if (e.getColumn().binding === 'category') {
          if (item.text === category16Text) {
            isCategory16 = true;
          }
          const value = item.text.split(categoryText)[1]?.trim();
          if ($_helper_isNumberType(value)) {
            vals.push(value);
          }
        } else if (e.getColumn().binding === 'category_id') {
          if (item.text === category16Text) {
            isCategory16 = true;
          } else {
            if ($_helper_isNumberType(item.text)) {
              vals.push(item.text);
            }
          }
        } else if (e.getColumn().binding === 'emission_per_product') {
          if (item.text === '-') {
            isNullText = true;
          } else {
            if ($_helper_isNumberType(item.text)) {
              vals.push(item.text);
            }
          }
        } else {
          if ($_helper_isNumberType(item.text)) {
            vals.push(item.text);
          }
        }
      });
      vals.sort((a, b) => {
        const numA = Number(a.replace(/,/g, ''));
        const numB = Number(b.replace(/,/g, ''));
        return numA - numB;
      })
      if (e.getColumn().binding === 'rate_all') {
        vals.forEach((item, index) => {
          vals[index] = `${item} %`;
        })
      }
      if (e.getColumn().binding === 'category') {
        vals.forEach((item, index) => {
          vals[index] = `${categoryText} ${item}`;
        })
        if (isCategory16) {
          vals.push(category16Text);
        }
      }
      if (e.getColumn().binding === 'category_id') {
        if (isCategory16) {
          vals.push(category16Text);
        }
      }
      if (e.getColumn().binding === 'emission_per_product') {
        if (isNullText) {
          vals.unshift(nullText);
        }
      }
      valueFilter.uniqueValues = vals;
      valueFilter.sortValues = false;
    },
    setHeightTable() {
      this.$nextTick(() => {
        const elementTable = document.getElementById('temp-table')
        const elementWrapTable = document.getElementsByClassName('wrap-table')
      })
    },
    removeMarqueeBorder() {
      this.theGrid.selectionChanged.addHandler((s, e) => {
        const firstVisibleColumn = s.columns?.find(item => item.isVisible === true)?.binding;
        let marquee = document.querySelector("#undoable-table .wj-marquee");
        if (this.tableName === 'searchExternalSource') {
          marquee = document.querySelector(".external-sources-table #undoable-table .wj-marquee");
        }
        if (firstVisibleColumn === s.columns[e.col]?.binding) {
          marquee?.classList.add("first-column");
        } else {
          marquee?.classList.remove("first-column");
        }
      })
    },
    onHandleShowError(value) {
      this.$emit('onHandleShowError', value)
    },
    onHandleChangeSelect(key, value) {
      this.$emit('onHandleChangeSelect', key, value)
    },
    getCategoryName(items, id) {
      const category = items.find(item => item.id === id);
      return category ? category.name : '';
    }
  },
  beforeDestroy() {
    this.actionUpdateIsFullScreen(false);
    window.removeEventListener('resize', this.handlerCalcHeight)
  },
  destroyed() {
    //sessionStorage.setItem("title", "");
    sessionStorage.setItem("duration_id", "");
    sessionStorage.setItem("pattern_id", "");
  },
};
</script>

<style lang="scss">
.select-types{
  &.full-screen {
   
    .select-item {
      height: 28px;
    }
    .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
      min-height: 28px;
    }
  }
} 
.wj-content.ctx-menu {
    padding: 3px;
    min-width: 120px;
    background: #FFFBDD !important;
    overflow: hidden;
}
.select-durations::-webkit-scrollbar {
    width: 16px;
    background: $monoOffWhite;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
/* Customize Track */
.select-durations::-webkit-scrollbar-track {
    border-radius: 4px;
}
$table: 1024px;

.fullmode-duration {
  display: flex;
  gap: 8px;
  align-items: center;

  .durations-pulldown-table {
    display: flex;
    flex-direction: row;
    .duration-header {
        height: 28px;
    }
    height: 28px;
    .duration-text {
      color: $monoBlack;

      /* Labels/Small */
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 163.636% */
      letter-spacing: 0.33px;
    }
    .durations-select-item {
      &.fullscreen-month-select {
        min-width: 58px;
      .v-input__control .v-input__slot {
        min-width: 58px;
        max-width: fit-content;
        .v-select__slot {
          justify-content: end;
        .v-select__selections {
          flex: unset;
          min-width: 18px;
          .v-select__selection.v-select__selection--comma {
            margin: 0;
            overflow: visible;
          }
          input {
            display: none;
          }
        }
        .v-input__append-inner {
          margin: 0;
        }
      }
      }
      &.select-item.v-input--is-focused .v-input__control .v-input__slot {
        background: $monoOffWhite !important;
      }
    }
      width: 79px;
      max-width: 79px;
      &.new-register {
        height: 28px;
      }
      &.fullscreen-month-select {
        min-width: 58px;
        .v-input__control .v-input__slot {
          min-width: 58px;
          max-width: fit-content;
          .v-select__slot {
            justify-content: end;
            .v-select__selections {
              flex: unset;
              min-width: 18px;
              .v-select__selection.v-select__selection--comma {
                margin: 0;
                overflow: visible;
              }
              input {
                display: none;
              }
            }
            .v-input__append-inner {
              margin: 0;
            }
          }
        }
        &.select-item.v-input--is-focused .v-input__control .v-input__slot {
          background: $monoOffWhite !important;
        }
      }
    }
    .durations-select-item {
      &.new-register {
        height: 28px;
      }
    }
    align-items: center;
    padding: 0px;
    gap: 8px;

    .durations-label {
      font-family: $mainFont;
      color: $monoBlack;

      /* Labels/Small */
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 163.636% */
      letter-spacing: 0.33px;
    }

    .data-is-registered::after {
      content: '';
      width: 8px;
      height: 8px;
      background: #bfd4d9;
      border-radius: 50%;
      position: relative;
      display: inline-block;
    }

    .duration-text {
      &.disabled {
        border: 1px solid rgba(42, 42, 48, 0.1);
        min-width: max-content;
        width: max-content;
      }
      border-radius: 4px;
      // width: 100%;
      //
      height: 28px;
      display: flex;
      align-items: center;

      span {
        padding: 8px 16px;
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        /* identical to box height, or 164% */

        letter-spacing: 0.03em;

        /* Mono/Black */

        color: #404D50;
        &.item-selected {
          color: $red;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .month-select {
  .select-item {
  .v-input__control {
    .v-select__slot {
      .v-select__selections {
        .v-select__selection {
          color: $monoBlack;

          /* Labels/Small */
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 163.636% */
          letter-spacing: 0.33px;
        }
      }
    }
    .v-input__append-inner {
      padding: 10px !important;
      padding-left: 0 !important;
    }
  }
}
}
}
// full mode styles durations
.fullmode  {
  .v-list {
    .v-list-item {
      padding: 8px !important;
      font-size: 11px !important;
      gap: 0px !important;
      .v-list-item__title {
        letter-spacing: 0.03em;
        font-weight: 500;
        font-size: 11px !important;
        line-height: 24px;
      }
    }
  }
  .v-select-list {
    .v-list-item--active {
      font-weight: 700 !important;
      .v-list-item__title {
        font-weight: 700 !important;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
.full-screen .grid-group.grid-fullscreen .select-item .v-input__slot .v-input__append-inner {
  padding: 8px !important;
}
.select-item .v-input__slot {
  height: 28px !important;
}

.custom-btn-fullmode {
  // max-height: 39px;
  // min-height: 28px!important;
  &.common-btn {
    max-height: 39px;
    height: auto!important;
    padding: 4px 8px 6px 8px !important;
    max-width: 100%;
  }
  .v-btn__content > div {
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 163.636% */
    letter-spacing: 0.33px;
    white-space: break-spaces;
  }
}
@media only screen and (max-width: 786px) {
  .custom-btn-fullmode {
    &.common-btn {
      padding: 4px 8px 6px 8px !important;
      max-height: 39px;
      min-height: 28px!important;
      max-width: 68%;
    }
  }
}
.data-is-registered {
  &::after {
    content: '';
    width: 8px;
    height: 8px;
    background: #bfd4d9;
    border-radius: 50%;
    position: relative;
    display: inline-block;
  }
}
/* Tooltip */
.tooltip-custom {
  width: 18px;
  height: 18px;
  background: rgba(121, 134, 134, 0.12) !important;
  border-radius: 50%;
  text-align: center;
  margin-left: 6px !important;

  &:hover {
    background: $goldMid !important;
    color: #fff;
    cursor: pointer;
  }
}

.pcaf-table {
  .wj-colheaders {
    .wj-row {
      &:last-child {
        .wj-header {
          .tooltip-custom {
            // display: none;
          }
        }
      }
      &:first-child {
        .wj-header {
          .tooltip-custom {
            // display: none;
          }
        }
      }
    }
  }
}

.edit-layer-name,
.switch-layer {
  &:hover {
    color: #fff;
    cursor: pointer;
  }
}
.icon-right {
  margin-left: auto;
  padding-top: 3px;
}

.edit-layer-name {
  width: 20px !important;
  height: 20px !important;
  margin-right: 3px !important;
}
.switch-layer {
  width: 27px !important;
  height: 16px !important;
}


#s3c11 {
  position: absolute;
  top: -25px;
  left: 210px;
}

#s3c10 {
  position: absolute;
  top: -25px;
  left: 220px;
}

.wj-cell.wj-header.wj-header-alt.wj-wrap.wj-filter-off {
  border-top: none !important;
}

.wj-clipboard {
  top: -150% !important;
}

#category-table {
  &:not(.full-screen) {
    position: relative;
    @include tablet {
      z-index: 97;
    }
  }
  padding-bottom: -10px !important;
  box-shadow: 0px 1.5773361921310425px 1.8540618419647217px 0px rgba(160, 181, 186, 0.37),
      0px 3.790558099746704px 4.455568313598633px 0px rgba(160, 181, 186, 0.27),
      0px 7.137284755706787px 8.389439582824707px 0px rgba(160, 181, 186, 0.22),
      0px 12.731696128845215px 14.965327262878418px 0px rgba(160, 181, 186, 0.15),
      0px 23.8132381439209px 27.990997314453125px 0px rgba(160, 181, 186, 0.10);
}

#frozen-column {
  &:not(.full-screen) {
    position: relative;
    @include tablet {
      z-index: 97;
    }
  }
  box-shadow: 0px 1.5773361921310425px 1.8540618419647217px 0px rgba(160, 181, 186, 0.37),
      0px 3.790558099746704px 4.455568313598633px 0px rgba(160, 181, 186, 0.27),
      0px 7.137284755706787px 8.389439582824707px 0px rgba(160, 181, 186, 0.22),
      0px 12.731696128845215px 14.965327262878418px 0px rgba(160, 181, 186, 0.15),
      0px 23.8132381439209px 27.990997314453125px 0px rgba(160, 181, 186, 0.10);
}

.override-btn {
  bottom: 40px;
  right: 40px;

  @media (max-width: $tablet) {
    bottom: 151px;
    right: 37px;
  }

  @media (max-width: $desktop) {
    bottom: 86px;
    right: 20px;
  }
  @media (max-width: 450px) {
    bottom: 150px !important;
  }
}

#btn-focus {
  right: 40px;
}
@media (max-width: 480px) {
  #btn-focus {
    right: 20px;
  }
}
.loadClass {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;

  span {
    display: none !important;
  }
}

:root {
  --width-table: 1366px;
}

.wj-listbox {
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px !important;
    background: #f7f7f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfd4d9;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid #f7f7f2;
    border-right: 4px solid #f7f7f2;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #bfd4d9;
  }
}

.wj-state-selected {
  background: none !important;
}

.wj-listbox[wj-part='dropdown'] {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
  max-height: 323px !important;

  &::-webkit-scrollbar {
    height: 8px !important;
    background: #f7f7f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfd4d9;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid #f7f7f2;
    border-right: 4px solid #f7f7f2;
    min-height: 50px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #bfd4d9;
  }

  background: $monoOffWhite !important;

  .wj-state-selected {
    //background: $bgLight !important;
    color: $monoBlack !important;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      background-image: url('../../assets/icons/pulldown/selected-icon.svg');
      background-size: 9px 6px;
      width: 9px;
      height: 6px;
      margin-left: 9.5px;
    }
  }

  .wj-listbox-item {
    max-width: 100% !important;
    border-top: 1px solid $dividerBlack;
    border-right: 1px solid $dividerBlack;
    min-height: 40px !important;
  }
}

.drop-down-custom {
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
  0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
  0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;

  .wj-listbox-item {
    border-top: 1px solid $dividerBlack !important;
    border-right: 1px solid $dividerBlack !important;
  }

  .wj-state-selected,
  .wj-state-last-selected {
    //background: #e3eded !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #404d50;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      background-image: url('../../assets/icons/pulldown/selected-icon.svg');
      background-size: 9px 6px;
      width: 9px;
      height: 6px;
      margin-left: 9.5px;
    }
  }
}
//TODO:  Add css for popup
.column-picker.wj-listbox {
}
.create-data-table {
  .wj-cell.wj-header[draggable] {
    display: flex;
    justify-content: center;
    border-top: none !important;

    &.wj-state-multi-selected {
      // border-right: 2px solid $blueMid !important;
      background: $blueMid !important;
      color: #fff !important;
      // pointer-events: none;
    }

    &::before {
      content: none !important;
    }
  }
}
.category-table {
  width: calc(100% + 40px);

  #load {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $goldMid;
    cursor: pointer;
    height: 100%;
    margin-top: -5px !important;
    z-index: 5;

    &:hover {
      color: $goldLight;
    }
  }

  ::-webkit-scrollbar {
    height: 15px;
    background: #e3eded;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bfd4d9;
    border-radius: 8px;
    z-index: 1000;
    border: 3px solid #e3eded;
    border-right: 4px solid #e3eded;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #bfd4d9;
  }

  ::-webkit-scrollbar-corner {
    background: $bgLight !important;
  }

  ::-webkit-scrollbar-button:horizontal:start:decrement {
    border-top: 1px solid $dividerBlack !important;
    width: 20px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACESURBVHgBbY0hDoNAEEXnD00xreoFUL1AVRUkcCkcDkW4B0dAYzkCARQKiyDsLgvJEiA7ZpL/X94HWS5Jc4/k8sIlLIrn0nQBmHVJNc60ZOErIncPdPkwtGDh3fU8ta0mMdu2D22cZV8W6seK3kbrmLIqy/Efhb0j4QL4kKIBNt1m2f4Klm0sPajfNPkAAAAASUVORK5CYII=');

    &:hover {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNiA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNiAwTDAgNEw2IDhWMFoiIGZpbGw9IiM3Qzg5OEMiLz4KPC9zdmc+Cg==');
    }
  }

  ::-webkit-scrollbar-button:horizontal:start:increment {
    border-top: 1px solid $dividerBlack !important;
    width: 20px;
    display: block;
    border-left: 1px solid $dividerBlack;
    border-right: 1px solid $dividerBlack;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACDSURBVHgBbY3NCYRADIUzY5Bl/wrYAqzDBrYVYWGPHjypIJ7EZrx5twgb8CKIDJJEPIzMgB8EAnn5HmR5HR0DF2hS8qJA4rSo4qxtn97RLkqriJf161rQTYqAtXzwfR80XCLhRLR5nwxgKAz68vcfPa0wj/Pj1jdJYs5OQjGM2Nm0yw7wKzFL5K8QXQAAAABJRU5ErkJggg==');

    &:hover {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNiA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNC43NjgzN2UtMDcgOEw2IDRMMS4xMzI1MWUtMDYgLTguMjY2ODZlLTA4TDQuNzY4MzdlLTA3IDhaIiBmaWxsPSIjN0M4OThDIi8+Cjwvc3ZnPgo=');
    }
  }

  /* Apply for checkbox & button */
  .wj-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wj-cell-check {
    margin: unset !important;
  }

  .wj-header.wj-colgroup.wj-align-center {

    &::before {
      content: none !important;
    }
  }
  .wj-header.wj-colgroup.wj-align-center:not(.status-field){
    border-bottom: none !important;
  }

  .wi-cell {
    &.wj-header {
      &.emission {
        background: $redExtraLight !important;
        color: $goldMid !important;
      }
    }
  }

  .wj-header.wj-colgroup {
    &.emission {
      background: $redExtraLight !important;
      color: $goldMid !important;
    }

    &::before {
      content: none !important;
    }
  }

  .wj-header.wj-align-right {
    &.emission {
      background: $redExtraLight !important;
      color: $goldMid !important;
    }
  }

  .wj-topleft {
    .wj-cell.wj-header {
      &::before {
        content: none;
      }

      &:hover {
        &::before {
          content: none;
        }
      }

      &.wj-header-alt {
        border-top: unset !important;
      }
    }
  }

  .wj-cell.wj-header {
    background-color: $bgLight !important;
    color: #7c898c !important;
    font-weight: 500 !important;
    font-size: 13px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    border-left: none !important;
    border-top: 1px solid $dividerBlack !important;
    overflow: unset;

    &.no-tooltip {
      &::before {
        content: unset;
      }
    }

    .wj-elem-filter {
      margin-left: -4px !important;
      margin-right: 3px !important;
    }

    &.wj-colgroup.wj-filter-on {
      height: 68px !important;
      padding: 0 8px 8px !important;

      .wj-glyph-filter {
        background-image: url('../../assets/icons/filterOn.svg');
      }
    }
    .required-field {
      color: $redMid;
    }
  }

  .wj-cell.wj-header:not(.single-row) {
    align-items: end !important;
  }

  .single-row {
    align-items: center !important;
  }

  .wj-cell.wj-header[draggable] {
    display: flex;
    justify-content: center;
    border-top: none !important;

    &.wj-state-multi-selected {
      border-right: 2px solid $blueMid !important;
      // background: $blueMid !important;
      // color: #fff !important;
      // pointer-events: none;
    }

    &::before {
      content: none !important;
    }
  }

  #temp-table {
    overflow: hidden;
    border: none !important;
    .empty-process {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      width: 100%;
      height: 148px;
      background: $bgLight;
      //margin-top: -7px;
      border-top: 1px solid rgba(42, 42, 48, 0.1);
      position: relative;
      box-shadow: 0px 23.8132px 27.991px rgba(160, 181, 186, 0.1), 0px 12.7317px 14.9653px rgba(160, 181, 186, 0.15), 0px 7.13728px 8.38944px rgba(160, 181, 186, 0.22056), 0px 3.79056px 4.45557px rgba(160, 181, 186, 0.265976), 0px 1.57734px 1.85406px rgba(160, 181, 186, 0.37);

      &-number {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 40px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $monoDark;
        height: 100%;
        border-right: 1px solid rgba(42, 42, 48, 0.1);;
      }
      .content {
        width: 100%;
        height: 148px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 20px;
        gap: 16px;

        .label {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.03em;
          color: $monoDark;
        }
      }
    }
  }

  .wrap-table.wj-flexgrid [wj-part='root'] {
    overflow-y: scroll !important;
    max-height: 465px !important;
    &::-webkit-scrollbar:vertical {
        width: 15px;
    }
    &::-webkit-scrollbar-track:vertical {
      border-top: 1px solid rgba(42, 42, 48, 0.1);
      border-left: 1px solid rgba(42, 42, 48, 0.1);
    }
    &::-webkit-scrollbar-track:horizontal {
      border-top: 1px solid rgba(42, 42, 48, 0.1);
    }
    &::-webkit-scrollbar-thumb:horizontal {
      border-radius: 8px;
      border-top: 4px solid !important;
      border-image: linear-gradient(to bottom, rgba(42, 42, 48, 0.1), #e3eded 2px) 10 0 round !important;
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
    &::-webkit-scrollbar-thumb:vertical {
      border-left: 4px solid !important;
      border-image: linear-gradient(to bottom, rgba(42, 42, 48, 0.1), #e3eded 2px) 6 0 round !important;
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  }

  .wrap-table {
    border: none !important;
    border-radius: 0 !important;
    background: transparent;
    position: relative;
    overflow: hidden;
    max-height: 465px !important;
    // top: 5px;

    .wj-glyph-filter {
      background-image: url('../../assets/icons/filterDefault.svg');
      background-position: center;
      margin-top: 2px;
      width: 20px !important;
      height: 20px !important;
      border: none !important;

      &:after {
        display: none;
      }

      &:hover {
        background-image: url('../../assets/icons/filterHover.svg');
      }
    }

    .wj-filter-on {
      .wj-glyph-filter {
        // transform: scale(1.25);
        background-image: url('../../assets/icons/filterOn.svg');
      }
    }
  }

  #theProductProcessGrid {
    &.wrap-table:not(.empty-process-fullscreen) {
      top: 5px;
      margin-top: -5px;
    }
  }

  .custom-colors {
    .wj-glyph-filter {
      color: red;
      font-size: 125%;
    }
  }

  #search-input {
    display: none;
    margin: 15px 0;
    position: relative;

    .search-btn {
      position: absolute;
      right: 0;
      height: 100%;
      z-index: 99;
      background: transparent;
    }

    .wj-input-group-btn {
      display: none !important;
    }
  }

  .wj-cell.wj-header.wj-state-invalid {
    background: $redExtraLight !important;
  }

  .wj-rowheaders {
    .wj-row {
      .wj-cell {
        &.wj-header {
          border-top: unset !important;
        }
      }
    }
  }
}
.external-sources-table {
  .category-table {
      .wrap-table.wj-flexgrid [wj-part='root'] {
        max-height: 520px !important;
      }
    }
  }

#frozen-column .wj-cell.wj-frozen:not(.wj-state-multi-selected),
#frozen-column .wj-cell.wj-frozen.wj-alt:not(.wj-state-multi-selected) {
		background: #F7F7F2 !important;
    border-right: 2px solid #99A6A9 !important;
}

#frozen-column .wj-flexgrid .wj-colheaders .wj-row .wj-cell.wj-frozen-col {
  border-right: 2px solid #99A6A9 !important;
}
.wj-flexgrid {


  .wj-colheaders {
    .wj-cell {
      padding: 5px 8px !important;
    }
  }

  .wj-glyph-down {
    border-top: 10px solid $monoDark;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    width: 16px !important;
    height: 16px !important;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  input[type='checkbox']:checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    box-shadow: none;
    text-align: center;
    width: 16px !important;
    height: 16px !important;
    background: $blueMid;
    border-radius: 4px;
  }

  input[type='checkbox']:checked:after {
    content: url('../../assets/icons/checkedbox.svg');
    color: white;
  }

  .wj-state-invalid[aria-required] {
    /* border: none !important; */
    color: $redMid !important;
  }

  .wj-state-invalid {
    border: none !important;
    border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  .wj-marker {
    width: 1000px !important;
    background-color: rgb(1, 255, 65) !important;
  }
  .wj-cell:not(.wj-cell[aria-readonly],.wj-state-active,.wj-header,.wj-has-changed) {
    color: $monoBlack !important;
    &.wj-duplicated-data {
      color: $redMid !important;
      &.emission {
        background: $redUltraLight !important;
      }
    }
    &.is-admin-read-only {
      color: $monoMid !important;
    }
  }
  .wj-cell {
    background: $monoOffWhite !important;
    font-size: 14px;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;
    align-items: center;

    &.cell-disable {
      background: #eef0f0 !important;
      color: #99a6a9 !important;
    }
    &.is-admin-read-only:not(.wj-state-multi-selected) {
      background: $monoExtraLight !important;
      color: $monoMid !important;
      .wj-elem-dropdown {
        display: none;
      }
    }
    &.is-admin-read-only {
      &.wi-header {
        background: $bgLight !important
      }
      &.wj-state-active {
        background: $monoExtraLight !important;
      }

      .wj-cell-check {
        background: rgba(0, 0, 0, 0.38) !important;
      }
      &.search-detail {
        background: $monoExtraLight !important;
      }
    }
    &.search-detail {
    padding: 2px !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    background: $monoOffWhite;
    &.btn-disabled {
      button {
        background: #eef0f0 !important;
      }
    }
    .wj-cell-maker {
      display: flex;
      height: 28px !important;
      max-height: 28px !important;
      // width: fit-content;
      padding: 4px 8px 6px 8px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 4px;
      background: $monoOffWhite;
      border: 1px solid rgba(42, 42, 48, 0.10);

      &:hover {
        color: $goldMid !important;
        background: unset !important;
      }
    }
    .search-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      gap: 6px;
      color: $monoBlack;
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.33px;
      img {
        width: 11.999px;
        height: 11.998px;
        flex-shrink: 0;
      }
    }
    &.is-admin-read-only {
      button {
        background: #e4e6e7 !important;
        color: #404d50 !important;
        border: unset !important;
        &:hover {
          background: #e4e6e7 !important;
          cursor: default;
        }
      }
    }
    }
    &.attach-file {
      &.btn-disabled {
        button {
          background: $monoLight !important;
          color: $monoBlack !important;
          border: unset !important;
        }
      }
      .wj-cell-maker {
        color: $goldMid !important;
        position: unset !important;
        background: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $goldLight;
        &:hover {
          background: #0072A3;
          border: unset;
          color: $monoWhite !important;
          .btn_custom {
            color: $monoWhite;
          }
        }
        &:has(.file-attached-container) {
          border: 1px solid $goldMid;
        }
        &:has(.file-attached-container.approved) {
          border: 1px solid $goldHeavy;
        }
      }
      &.wj-has-changed {
        button {
          color: $goldHeavy !important;
          border: 1px solid $goldHeavy;
        }
        &.is-admin-read-only {
          button {
            background: $monoLight !important;
            color: $monoBlack !important;
            border: unset;
          }
        }
      }
      .file-attached-container {
        width: 18px;
        height: 18px;
        img {
          width: 18px;
          height: 18px;
          flex-shrink: 0;
        }
      }
      &.hide-filter {
        .wj-btn-glyph {
          display: none;
        }
      }
    }

    // white-space: pre-wrap;
  }

  .wj-cell-maker {
    display: flex;
    max-height: 23px;
    justify-content: center;
    background: $monoLight;
    border-radius: 4px;
    padding: 1px 6px 3px;
    text-align: center;
    font-size: 13px;
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    position: unset !important;
    cursor: pointer;

    button {
      text-align: center;
    }

    &:hover {
      color: $monoWhite !important;
      background: #0072a3;
    }
  }
  .wj-cell[aria-readonly] {
    &.wj-state-active {
      color: $monoMid !important;
    }
  }
  .wj-cell[aria-readonly]:not(.wj-state-multi-selected) {
    background: $monoOffWhite !important;
    &.is-admin-read-only {
      background: $monoExtraLight !important;
    }
  }
  .wj-cell[aria-readonly] {
    color: $monoMid !important;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 24px;
    border-right: unset !important;
    border-bottom: unset !important;
    align-items: center;
    gap: 6px;
    padding: 5px 8px 5px !important;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;

    &.btn-db {
      display: flex;
      justify-items: center;
      align-items: flex-start;
      color: $monoBlack !important;
      padding: 4px !important;
      &:not(.wj-state-multi-selected) {
        background: $monoOffWhite !important;
      }
    }

    &.btn-disabled {
      color: $monoMid !important;
      pointer-events: none;
    }

    &.loadClass {
      background: #f7f7f2 !important;
    }

    &.wj-state-active {
      background: $monoOffWhite !important;
      border: unset !important;
      border-right: 1px solid $dividerBlack !important;
      border-bottom: 1px solid $dividerBlack !important;

      &::after {
        background: unset;
      }
      &:not(.search-detail) {
        border: unset !important;
      }
    }

    &.wj-has-changed {
      color: $goldHeavy !important;
    }
    &:not(.wj-state-multi-selected,.emisson) {
      &.wj-has-changed {
        background: $monoOffWhite;
      }
    }
    &.casbee-detail {
      background: $monoOffWhite !important;
      &.wj-state-multi-selected {
        background: $blueExtraLight !important;
      }
      .wj-cell-maker {
        .casbee-btn_custom {
          width: 100%;
          color: $goldMid;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
        }
        &:hover {
          background: #0072A3;
          border: unset;
          .casbee-btn_custom {
            color: $monoWhite;
          }
        }
      }
    }
    &.btn-detail-product:not(.wj-state-multi-selected) {
      background: $monoOffWhite !important;
    }
    &.btn-detail-product {
      .wj-cell-maker {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 0;

        min-width: 38px;
        height: 24px;
        min-height: 24px;
        border: 1px solid $goldLight;
        border-radius: 4px;
        background: $monoOffWhite;
        color: $goldMid;
        &:hover {
          background: #0072a3;
          border: unset;
          color: $monoWhite;
        }
      }
    }
  }

  .wj-cells .wj-state-active {
    background: $monoWhite !important;
    color: #000;
    border: 1px solid $blueMid;
    &.cell-background {
      background-color: $monoOffWhite !important;
    }
    &.auto-increment {
      background: $monoOffWhite !important;
    }
  }

  .custom-btn {
    padding: 2px 10px;
    background: #e4e6e7;
    border-radius: 4px;
  }
  .wj-cell:not(.wj-has-changed, .wj-duplicated-data,.wj-state-multi-selected, .wj-header) {
    &.auto-increment {
      background: $monoOffWhite !important;
      &.is-admin-read-only{
        background: $monoExtraLight !important;
      }
    }
    &.emission {
      background: $redUltraLight !important;
    }
  }
  .wj-cell:not(.wj-has-changed, .wj-duplicated-data, .wj-header) {
    padding: 5px 8px !important;
    &.empty {
      .wj-btn {
        display: none !important;
      }
    }

    &.auto-increment {
      color: $monoMid !important;

    }

    &.emission {
      color: $goldMid !important;
      &.is-admin-read-only {
        color: $monoMid !important;
      }
    }

    &.wj-header {
      &.emission {
        background: $redExtraLight !important;
        color: $goldMid !important;
      }
    }

    &.btn-detail-product {
      padding: 4px !important;
    }
    &.product-delete-btn {
      padding: 4px !important;
    }
  }
  .wj-cell.wj-has-changed {
    padding: 5px 8px !important;

    &.empty {
      .wj-btn {
        display: none !important;
      }
    }

    &.auto-increment {
      color: $goldHeavy !important;
      &:not(.wj-state-multi-selected) {
        background: $monoOffWhite !important;
        &.is-admin-read-only {
          background: $monoExtraLight !important;
        }
      }
    }

    &.emission {
      color: $goldHeavy !important;
      &:not(.wj-state-multi-selected) {
        background: $redUltraLight !important;
      }
    }

    &.wj-header {
      &.emission {
        background: $redExtraLight !important;
        color: $goldMid !important;
      }
    }
    &.is-admin-read-only {
      color: $monoMid !important;
    }
  }
}

.facility-table {
  .wj-colheaders {
    .wj-cell {
      padding: 0 8px !important;
    }
  }
}

.external-sources-table {
  .wj-colheaders {
    .wj-cell {
      padding: 3px 8px 5px 8px !important;
    }
  }
  .wj-cell.wj-header {
    .wj-elem-filter {
      margin-right: 0 !important;
    }
  }
}

.center-table {
  .wj-colheaders {
    .wj-cell {
      padding: 0 8px !important;
    }
  }
}

.wj-flexgrid {
  overflow: invisible !important;

  .wj-colheaders .wj-header.wj-state-multi-selected {
    box-sizing: padding-box !important;
    // border-bottom: 2px solid $blueMid !important;
    &::after {
      content: " ";
      border-bottom: 2px solid $blueMid !important;
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .wj-cells .wj-cell .wj-state-active {
    background: transparent;
    color: #000;
    border: 2px solid rgb(15, 228, 69);

    &::after {
      content: '';
      width: 5px;
      height: 5px;
      background: rgb(7, 115, 204);
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .wj-colheaders {
    .wj-row {
      .wj-cell {
        &.wj-header {
          // border-bottom: unset !important;
          &.wj-sort-asc {
            .wj-glyph-up {
              display: none !important;
            }
          }
          &.wj-sort-desc {
            .wj-glyph-down {
              display: none !important;
            }
          }
        }
      }
    }
  }

  // style for table readonly
  &.wj-state-readonly {
    .wj-colheaders .wj-header.wj-state-multi-selected {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1) !important;
    }
  }
}

.table-toolbar {
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  background: #e3eded;
  margin-bottom: -5px;
  justify-content: space-between;
  gap: 8px;

  .button-action-table-first {
    display: flex;
  }

  .toolbar-fullscreen {
    .fullscreen-item {
      display: flex;
      gap: 4px;
      margin-right: 10px;

      .fullscreen-text {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #404d50;
      }

      .fullscreen-switch-btn {
        height: 16px;

        .v-input__control {
          height: 16px;

          .v-input--selection-controls__input {
            height: 20px !important;
            margin-right: 0 !important;
            width: 27px !important;

            input {
              height: 16px !important;
            }

            .v-input--switch__track {
              width: 27px !important;
              background: #7C898C !important;
            }

            .v-input--switch__thumb {
              width: 16px !important;
              height: 16px !important;
              margin-top: 2px !important;
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24) !important;
            }
          }
        }

        &.v-input--is-label-active {
          .v-input__control {
            .v-input--selection-controls__input {
              .v-input--switch__track {
                background: $blueMid !important;
              }

              .v-input--switch__thumb {
                margin-left: -9px !important;
              }
            }
          }
        }
      }

      &:first-child {
        padding: 4px 6px 4px 4px;
        background: #f7f7f2;
        border: 1px solid rgba(42, 42, 48, 0.1);
        border-radius: 4px;
        max-width: 132px;
        height: 28px;
      }
    }
  }

  .toolbar-undo {
    // width: calc(100% - 140px);
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1023px) {
  .category-table {
    margin-left: -20px !important;
    overflow: hidden;
    box-shadow: 0px 1.5773361921310425px 1.8540618419647217px 0px rgba(160, 181, 186, 0.37),
      0px 3.790558099746704px 4.455568313598633px 0px rgba(160, 181, 186, 0.27),
      0px 7.137284755706787px 8.389439582824707px 0px rgba(160, 181, 186, 0.22),
      0px 12.731696128845215px 14.965327262878418px 0px rgba(160, 181, 186, 0.15),
      0px 23.8132381439209px 27.990997314453125px 0px rgba(160, 181, 186, 0.10);
  }
}

@include desktop {
  .table-toolbar {
    flex-direction: row;
    padding: 8px 40px;
    height: 44px;
  }
  .category-table {
    width: 100%;
  }
  .width-fit-content {
    width: fit-content !important;
  }
  .grid-group .top-side .left .fullscreen-item:first-child.width-fit-content-vi {
    max-width: unset ;
    width: 150px;
    .fullscreen-text {
      width: auto ;
    }
  }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none;
}
.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 40px;
}
.duration-header {
  &.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 28px;
  }
}
.select-idea-source {
  &.v-text-field.v-text-field--solo.v-input--dense > .v-input__control > .v-input__slot{
    box-shadow: unset !important;
    border: 1px solid $dividerBlack !important;
    padding:5px 0px 7px 10px !important;
    .v-select__selections {
      .v-select__selection.v-select__selection--comma {
        min-width: 66px;
        color: $monoBlack;
        font-size: 11px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.33px;
        margin: 0;
      }
      input {
        display: none;
      }
    }
    .v-input__append-inner {
      padding: 8px !important;
      .v-input__icon.v-input__icon--append {
        height: 14px !important;
        width: 14px !important;
        min-width: 14px;
      }
    }
  }
}

.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 40px;
}

.wj-cell.wj-has-changed {
  color: $goldHeavy !important;
  &.is-admin-read-only {
    color: $monoMid !important;
  }
}

.wj-cell.is-admin-read-only {
  .wj-elem-dropdown {
    display: none;
  }
}

.wj-cell.wj-has-changed-mark, .wj-cell.wj-has-unread-mark {
  overflow: hidden !important;
}

.wj-dropdown:not(.wj-state-collapsed) {
  .wj-btn {
    border: none !important;
    &:hover {
      background: none !important;
    }
  }
  .wj-glyph-down {
    rotate: 180deg;
  }
}

.wj-listbox.wj-dropdown-panel .wj-state-match{
  min-width: fit-content;
}

.wj-dropdown-panel {
  box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
    0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
    0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
    0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
    0px 36px 33px 0px rgba(160, 181, 186, 0.07);
  margin-top: 4px;
  .wj-listbox {
    .wj-listbox-item {
      cursor: pointer;
      padding: 7px 16px 9px !important;
      font-weight: 500;
      &:hover {
        background: #e3eded !important;
      }
    }
  }
}

.wj-dropdown-panel.wj-control.wj-content.wj-columnfiltereditor.wj-state-empty.wj-state-focused {
    z-index: 98 !important;
}
.v-dialog__content{
  .wj-dropdown-panel.wj-control.wj-content.wj-columnfiltereditor.wj-state-empty.wj-state-focused {
    z-index: 1500 !important;
    .wj-filtertype {
      a {
        color: inherit !important;
      }
    }
  }
  .wj-dropdown-panel.wj-control.wj-content.wj-columnfiltereditor {
    .wj-filtertype {
      a {
        color: inherit !important;
      }
    }
  }
}
.wj-cell.wj-has-changed-mark:after, .wj-cell.wj-has-unread-mark:after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  top: -10px;
  border: 10px solid transparent;
  border-left: 10px solid #E0A329;
}

.wj-cell.wj-has-unread-mark:after {
  border-left: 10px solid #132D39;
}

.wj-tooltip:has(.wj-cell-changed) {
  padding: 2px 10px 4px;
  background: #E0A329;
  color: #FFF;
  border: 1px solid $goldHeavy;
  box-shadow: unset;
}

.wj-cell.wj-has-changed[aria-readonly] {
  color: $goldHeavy !important;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 24px;
  border-right: unset !important;
  border-bottom: unset !important;
  align-items: center;
  gap: 6px;
  padding: 5px 8px 5px !important;
  border-right: 1px solid $dividerBlack !important;
  border-bottom: 1px solid $dividerBlack !important;
  &:not(.wj-state-multi-selected,.emission) {
    background: $monoOffWhite !important;
  }
  &.btn-db {
    display: flex;
    justify-items: center;
    align-items: flex-start;
    background: $monoOffWhite !important;
    color: $monoBlack !important;
    padding: 4px !important;
    &.is-admin-read-only {
      background: $blueExtraLight !important;
    }
    &.attach-file {
      &.wj-state-multi-selected {
        background: $blueExtraLight !important;
      }
    }
  }

  &.btn-disabled {
    color: $monoMid !important;
    pointer-events: none;
  }

  &.loadClass {
    background: #f7f7f2 !important;
  }

  &.wj-state-active {
    background: $monoOffWhite !important;
    border: unset !important;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;

    &::after {
      background: unset;
    }
    &:not(.search-detail) {
      border: unset !important;
    }
  }
}
// duplicate data
.wj-cell {
  &.wj-duplicated-data {
    color: $redMid !important;
    &.auto-increment {
      color: $redMid !important;
    }
  }
  &.wj-duplicated-data[aria-readonly] {
    background: $monoExtraLight !important;
    color: $redMid !important;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 24px;
    border-right: unset !important;
    border-bottom: unset !important;
    align-items: center;
    gap: 6px;
    padding: 5px 8px 5px !important;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;
  }
}
.category-table:not(.full-screen){
  .table-viewer {
  .grid-group {
    margin-left: 20px;
    margin-right: 20px;
    @include desktop {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  #temp-table {
    .wrap-table {
      padding: 0 20px !important;
    }
    @include desktop {
      .wrap-table {
        padding: 0 !important;
      }
    }
  }
}
}

.grid-group{
  align-items: center;
  grid-gap: 8px;
  &.grid-fullscreen {
    grid-gap: 0px;
    .top-side {
      .right {
        // margin-right: 8px;
    }
    }
    .select-item .v-input__slot {
      height: 28px !important;
      padding: 5px 0px 7px 10px !important;
      .v-input__append-inner {
        padding: 10px !important;
        padding-left: 0 !important;
      }
    }
  }
  background: $bgLight;
  // padding: 8px 20px;
  margin-bottom: -5px;
  display: grid;
    grid-template-areas:
    "top top top"
    "mid"
    "bot bot";
  .db-idea-source {
    width: 106px;
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: center;
    margin-left: -8px;;
    .duration-header {
        height: 28px;
    }
  }
  .top-side{
    display: flex;
    // grid-gap: 8px;
    display: flex;
    padding: 8px 0px 8px 40px;
    align-items: center;
    gap: 8px;
    &.hide-undo-redo {
      gap: 0px;
    }
    align-self: stretch;
    .left{
      min-width: 120px;
      .fullscreen-item {
        display: flex;
        gap: 4px;

        .fullscreen-text {
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          letter-spacing: 0.03em;
          color: $monoBlack;
          white-space: nowrap;
          width: 67px;
          text-align: center;
        }

        .fullscreen-switch-btn {
          height: 16px;

          .v-input__control {
            height: 16px;

            .v-input--selection-controls__input {
              height: 20px !important;
              margin-right: 0 !important;
              width: 27px !important;

              input {
                height: 16px !important;
              }

              .v-input--switch__track {
                width: 27px !important;
                height: 10px;
                background: $monoDark !important;
                right: 0;
                left: 0;
                top: calc(50% - 5px);
              }

              .v-input--switch__thumb {
                width: 16px !important;
                height: 16px !important;
                margin-top: 2px !important;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24) !important;
              }

              &:hover {
                .v-input--selection-controls__ripple::before {
                  background: $goldMid;
                }
                .v-ripple__animation {
                  background: $goldMid;
                }
              }
            }
          }

          &.v-input--is-label-active {
            .v-input__control {
              .v-input--selection-controls__input {
                .v-input--switch__track {
                  background: $blueMid !important;
                }
                .v-input--switch__thumb {
                  margin-left: -9px !important;
                }
                .v-input--selection-controls__ripple {
                  left: -24px !important;
                }
                &:hover {
                  .v-input--selection-controls__ripple:before {
                    background: $blueMid;
                  }
                  .v-ripple__animation {
                    background: $blueMid;
                  }
                }
              }
            }
          }
        }

        &:first-child {
          padding: 4px 6px 4px 4px;
          background: $monoOffWhite;
          border: 1px solid rgba(42, 42, 48, 0.1);
          border-radius: 4px;
          max-width: 132px;
          height: 28px;
        }
      }
    }
    .right{
      //TODO
      // margin-right: 24px;
      // flex-grow: 1;
      display: flex;
      justify-content: space-between;
    }
  }
  .mid-side{
    grid-area: mid;
    padding: 8px 0px;
  }
  .bottom-side{
    grid-area: bot;
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;

  }
  .comment-side {
    // margin-left: auto;
    // margin-left: 8px;
    margin-right: 10px;
    padding: 8px 0px;
  }
  .total-result {
    grid-area: total;
    justify-self: end
  }
  .method {
    grid-area: method;
  }
  .year-select {
    grid-area: year;
  }
  .month-select {
    grid-area: month;
  }
  .duration-side {
    grid-area: duration;
    display: grid;
    padding: 8px 20px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background: var(--background-transparent, rgba(121, 134, 134, 0.12));
    grid-template-columns: repeat(3, auto) 1fr;
    grid-template-areas: "year month method total";
    .method {
      display: flex;
      gap: 8px;
      align-items: center;
      .method-label {
        color: $monoBlack;
        /* Labels/Small */
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 163.636% */
        letter-spacing: 0.33px;
      }
      .category-name,
      .method-name {
        display: flex;
        align-items: center;
        font-size: 11px;
        min-width: 64px;
        border-radius: 4px;
        font-weight: 700;
        color: #404D50;
        padding: 4px 16px !important;
        border: 1px solid rgba(42, 42, 48, 0.1);
        span {
          overflow: hidden;
          color: $monoBlack;
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          letter-spacing: 0.03em;
        }
      }
      .category-name {
        min-width: 220px;
        span { 
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .emissions {
      display: flex;
      gap: 8px;
      align-items: center;
      .total-label {
        color: $monoBlack;
        /* Labels/Small */
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 163.636% */
        letter-spacing: 0.33px;
      }
      .total-emission {
        color: $goldMid;
        text-align: right;
        font-family: Century Gothic;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 150% */
        letter-spacing: 0.72px;
        .label-unit {
          margin-left: 8px;
          color: $goldMid;
          /* Score/Unit/Big */
          display: inline-block;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.7px;
        }
      }
    }
  }
  @include desktop {
    margin-right: 0px;
    // padding: 8px 40px !important;
    &.view-details-wf-returned {
      .comment-side {
        margin-right: 36px;
      }
    }
  }
  
}

@media (max-width: 1023px) {
.fullmode-duration {
  .durations-pulldown-table {
    .durations-label {
      display: none;
    }
  }
  .duration-header {
    height: 28px;
  }
}
.grid-group{
  &.compressed{
    grid-template-areas:
    "top mid comment"
    "bot bot bot";
    // grid-template-columns: 1fr auto;
    .bottom-side{
      display: flex;
      justify-content: flex-end;
      grid-gap: 8px;
    }
    &.is-idea-source-screen {
      grid-template-areas:
      "top idea-db"
      "bot bot";
      grid-column-gap: 8px;
      grid-row-gap: 0px;
      .top-side{
        padding-top: 0px;
      }
      .db-idea-source {
        margin-right: 20px;
      }
    }
    &.is-primary-screen {
      grid-template-areas: "top bot";
      .top-side{
        margin-bottom: 8px;
      }
      .bottom-side {
        padding-top: 8px;
        margin-left: 0px;
        .button-action-table {
          justify-content: end;
          .button-submit-area {
            flex-grow: unset;
          }
        }
      }
    }
  }
  grid-gap: 8px;
  grid-template-columns: auto 1fr;
  grid-template-areas:
      "top mid comment"
      "bot bot bot";
  .comment-btn {
    margin-left: auto;
  }

  .top-side {
      padding: 8px 0px 0px 20px;

      .left {
        min-width: 61px !important;
        .fullscreen-item {
          display: flex;
          gap: 4px;

          .fullscreen-text {
            display: none;
          }

          .fullscreen-switch-btn {
            height: 16px;

            .v-input__control {
              height: 16px;
              justify-content: center;

              .v-input--selection-controls__input {
                height: 20px !important;
                margin-right: 0 !important;
                width: 27px !important;

                input {
                  height: 16px !important;
                }

                .v-input--switch__track {
                  width: 27px !important;
                  background: $monoDark !important;
                }

                .v-input--switch__thumb {
                  width: 16px !important;
                  height: 16px !important;
                  margin-top: 2px !important;
                  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24) !important;
                }
              }
            }

            &.v-input--is-label-active {
              .v-input__control {
                .v-input--selection-controls__input {
                  .v-input--switch__track {
                    background: $blueMid !important;
                  }

                  .v-input--switch__thumb {
                    margin-left: -9px !important;
                  }
                }
              }
            }
          }

          &:first-child {
            padding: 4px 6px 4px 4px;
            background: $monoOffWhite;
            border: 1px solid rgba(42, 42, 48, 0.1);
            border-radius: 4px;
            height: 28px;
            max-width: 61px !important;
            align-items: center;
          }
        }
      }
    }
  .mid-side {
    padding-bottom: 0px;
    &.not-comment-area {
      margin-right: 12px;
    }
  }
  .bottom-side {
      display: flex;
      justify-content: flex-end;
      grid-gap: 8px;
      margin-bottom: 8px;
  }
  .comment-side {
    padding-bottom: 0px;
    margin-right: 20px;
  }
  &.grid-fullscreen {
    gap: 8px;
    grid-template-areas:
    "duration duration duration"
      "top mid comment"
      "bot bot bot";
    &.compressed {
      grid-template-areas:
        "top comment"
        "duration duration duration"
        "mid mid mid"
        "bot bot bot";
        &.is-view-data {
          // grid-template-areas:
          // "top top top"
          // "duration duration duration"
          // "bot bot bot";
        }
      }
    .duration-side {
      grid-template-columns: auto auto 1fr;
      grid-template-areas:
        "year month method"
        "total total total";
      .total-result {
        justify-self: unset;
        .emissions {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    &.is-over-text-methods {
      .duration-side {
        grid-template-columns: auto 1fr;
        grid-template-areas:
          "year month"
          "method method"
          "total total";
      }
    }
  }
  &.grid-setting-screen {
    .top-side {
      padding-bottom: 8px;
    }
    .bottom-side {
      margin-bottom: 0px;
    }
    grid-template-areas:
      "top mid bot"
  }
}
}
@media (min-width: 1024px) {
  .grid-group{
    align-items: center;
    grid-template-areas: "top mid comment bot";
    grid-template-columns: auto 1fr auto;
    &.is-over-text {
        grid-template-areas:
        "top mid comment"
        "bot bot bot";
        grid-row-gap: 0px;
      .bottom-side {
        margin-left: 40px;
        margin-bottom: 8px;
      }
      .mid-side {
        &.not-comment-area {
          margin-right: 32px;
        }
      }
      .comment-side {
        margin-right: 40px;
      }
    }

    &.grid-fullscreen {
      align-items: center;
      grid-template-areas:
      "duration duration duration duration"
      "top mid comment bot";
      &.is-over-text {
        grid-template-areas:
        "duration duration duration"
        "top mid comment"
        "bot bot bot";
        .bottom-side {
          margin-left: 40px;
          margin-bottom: 8px;
        }
        .comment-side {
          margin-right: 40px !important;
        }
      }
      &.is-primary-screen {
        grid-template-areas: "top mid comment bot";
      }
      &.compressed {
        grid-template-areas:
        "top duration comment"
        "mid mid mid mid"
        "bot bot bot";
        grid-gap: 4px;
        // &.is-view-data {
        //   grid-template-areas:
        //   "top duration"
        //   "bot bot";
        // }
      }
      .method {
        ::v-deep .common-btn{
          height: 24px !important;
        }
      }
      .top-side {
          .right {
            margin-right: 8px;
          }
      }
      .comment-side {
        // margin-left: 8px;
        margin-left: 3px;
        margin-right: 0;
      }
      .bottom-side {
        // margin-left: 38px;
        margin-left: 36px;
      }
      &.view-details-wf-returned {
        .comment-side {
          margin-right: 40px;
        }
      }
    }
    &.grid-setting-screen {
      grid-template-areas:
        "top mid bot"
    }
    .bottom-side {
      margin-right: 40px;
      margin-left: 16px;
    }
    .duration-side {
      padding: 8px 40px;
    }
  }
}
@media screen and (min-width: 360px) and (max-width: 450px) {
  .grid-group {
  .emissions {
    align-items: unset!important;
    .total-label {
      margin-top: 6px;
    }
    .total-emission {
      display: inline-block;
      word-break: break-all;
      max-width: 218px;
    }
  }
}
}

@media screen and (min-width: 1024px) and (max-width: 1230px) {
  .height-fit-content {
    height: fit-content !important;
    padding: 4px 0;
  }
}

@media screen and (max-width: 768px) {
  .height-fit-content {
    height: fit-content !important;
  }
}
</style>

<style scoped lang="scss">
.select-types {
  &.full-screen {
    display: flex;
    align-items: center;
    // min-height: 72px;
    gap: 20px;
  }
}
@include desktop {
  .grid-group{
    &.grid-fullscreen {
      .comment-side {
        // margin-right: 2px !important;
        // margin-right: 0px !important;
      }
    }
  }
}

#undoable-table {
  margin-top: -5px;
  .wrap-table {
    top: 5px;
  }
}

@media screen and (min-width: 360px) {
  .full-screen {
    .grid-fullscreen {
      .custom-btn-fullmode {
        &.common-btn {
          height: auto !important;
          max-width: fit-content;
        }
      }
    }
  }
}
.external-sources-table {
  .category-table {
    .wrap-table {
      max-height: 520px !important;
    }
  }
}
.period-description {
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
    .title1 {
      color: $monoBlack;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: 0.42px;
      flex-shrink: 0;
    }
    .duration-date {
      color: $monoBlack;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
    }
  }
</style>
